'use strict';

var debounce = require('lodash/debounce');
var endpoint = $('.suggestions-wrapper').data('url');
var minChars = 1;

var suggestedRedirectUrl = null;


/**
 * Retrieves Suggestions element relative to scope
 *
 * @param {Object} scope - Search input field DOM element
 * @return {JQuery} - .suggestions-wrapper element
 */
function getSuggestionsWrapper(scope) {
    return $(scope).closest('.js-modal-search').find('.suggestions-wrapper');
}

/**
 * Determines whether DOM element is inside the .search-mobile class
 *
 * @param {Object} scope - DOM element, usually the input.search-field element
 * @return {boolean} - Whether DOM element is inside  div.search-mobile
 */
function isMobileSearch(scope) {
    return !!$(scope).closest('.search-mobile').length;
}

/**
 * Remove modal classes needed for mobile suggestions
 *
 */
function clearModals() {
    $('header').siblings().attr('aria-hidden', 'false');
    $('.suggestions').removeClass('modal');
}

/**
 * Toggle search field icon from search to close and vice-versa
 *
 * @param {string} action - Action to toggle to
 */
function toggleSuggestionsIcon(action) {
    var mobileSearchIcon = '.search-mobile button.';
    var iconSearch = 'fa-search';
    var iconSearchClose = 'fa-close';

    if (action === 'close') {
        $(mobileSearchIcon + iconSearch).removeClass(iconSearch).addClass(iconSearchClose).attr('type', 'button');
    } else {
        $(mobileSearchIcon + iconSearchClose).removeClass(iconSearchClose).addClass(iconSearch).attr('type', 'submit');
    }
}

/**
 * Determines whether the "More Content Below" icon should be displayed
 *
 * @param {Object} scope - DOM element, usually the input.search-field element
 */
function handleMoreContentBelowIcon(scope) {
    if (($(scope).scrollTop() + $(scope).innerHeight()) >= $(scope)[0].scrollHeight) {
        $('.more-below').fadeOut();
    } else {
        $('.more-below').fadeIn();
    }
}

/**
 * Positions Suggestions panel on page
 *
 * @param {Object} scope - DOM element, usually the input.search-field element
 */
function positionSuggestions(scope) {
    var outerHeight;
    var $scope;
    var $suggestions;
    var top;

    if (isMobileSearch(scope)) {
        $scope = $(scope);
        top = $scope.offset().top;
        outerHeight = $scope.outerHeight();
        $suggestions = getSuggestionsWrapper(scope).find('.suggestions');
        $suggestions.css('top', top + outerHeight);

        handleMoreContentBelowIcon(scope);

        // Unfortunately, we have to bind this dynamically, as the live scroll event was not
        // properly detecting dynamic suggestions element's scroll event
        $suggestions.scroll(function () {
            handleMoreContentBelowIcon(this);
        });
    }
}


/**
 * Process Ajax response for SearchServices-GetSuggestions
 *
 * @param {Object|string} response - Empty object literal if null response or string with rendered
 *                                   suggestions template contents
 */
function processResponse(response) {
    var $suggestionsWrapper = getSuggestionsWrapper(this).empty();
    $.spinner().stop();

    let $modalSearch = $('.js-modal-search');

    suggestedRedirectUrl = null;

    if (typeof (response) !== 'object') {
        let $response = $(response);
        let $searchPhraseRefinementsSidebar = $response.filter('#searchPhraseRefinementsSidebar');
        // Remove the current sidebar
        $modalSearch.siblings('#searchPhraseRefinementsSidebar').remove();

        if ($searchPhraseRefinementsSidebar.length > 0) {
            // Add the new sidebar
            $searchPhraseRefinementsSidebar.insertAfter($modalSearch);
            $response = $response.not('#searchPhraseRefinementsSidebar');
        }


        $suggestionsWrapper.append($response.html()).show();
        $('.js-modal-search').addClass('md-full');
        $('.js-row-content-highlighted').addClass('d-none');
        $('.js-reset-button-search').removeClass('d-none');
        $('.js-filter-button-search').removeClass('d-none');
        calculateModalHeight();
        positionSuggestions(this);

        // Trigger screen reader by setting aria-describedby with the new suggestion message.
        var suggestionsList = $('.suggestions .item');
        if ($(suggestionsList).length) {
            $('input.search-field').attr('aria-describedby', 'search-result-count');
        } else {
            $('input.search-field').removeAttr('aria-describedby');
        }
    } else if (response.suggestedRedirect) {
        suggestedRedirectUrl = response.suggestedRedirect;
    }
}

/**
 * Retrieve suggestions
 *
 * @param {Object} scope - Search field DOM element
 */
function getSuggestions(scope) {
    if ($(scope).val().length >= minChars) {
        $.spinner().start();
        $.ajax({
            context: scope,
            url: endpoint + encodeURIComponent($(scope).val()),
            method: 'GET',
            success: processResponse,
            error: function () {
                $.spinner().stop();
            }
        });
    } else {
        toggleSuggestionsIcon('search');
        $(scope).siblings('.reset-button').removeClass('d-sm-block');
        clearModals();
        getSuggestionsWrapper(scope).empty();
    }
}

// Here you can also integrate the filter cleaning logic
function clearSuggestions() {
    $('.js-search-field').val('');
    $('.js-modal-search').removeClass('md-full');
    $('.js-suggestions-wrapper').find('.row').remove();
    $('.js-row-content-highlighted').removeClass('d-none');
    $('.js-reset-button-search').addClass('d-none');
    $('.js-filter-button-search').addClass('d-none');

    var modal = $('.js-modal-search');
    var modalDialog = modal.find('.modal-dialog');
    modalDialog.removeAttr('style');
}

function calculateModalHeight() {
    var modal = $('.js-modal-search');
    var modalDialog = modal.find('.modal-dialog');

    // Set modal height based on content
    if (window.isMobile()) {
        modalDialog.css('height', '100vh');
    } else {
        modalDialog.removeAttr('style');
    }
}

function changeLabelSearch() {
    var windowWidth = $(window).width();
    var inputSearch = $('.js-search-field');
    var desktopPlaceholder = inputSearch.attr('aria-label');
    if (windowWidth <= 768) {
        var mobilePlaceholder = inputSearch.data('label-mobile');
        inputSearch.attr('placeholder', mobilePlaceholder);
    } else {
        inputSearch.attr('placeholder', desktopPlaceholder);
    }
}

module.exports = function () {
    $('form[name="simpleSearch"]').submit(function (e) {
        e.preventDefault();
        if (suggestedRedirectUrl) {
            window.location.href = suggestedRedirectUrl;
        }
    });

    $(window).resize(function () {
        calculateModalHeight();
        changeLabelSearch();

        if (window.isMobile()) {
            if($('#searchPhraseRefinementsSidebar').hasClass('show')) {
                $('#searchPhraseRefinementsSidebar').css('overflow-y', 'hidden');
               //overflow-y: auto;
            }
        } else {
            if($('#searchPhraseRefinementsSidebar').hasClass('show')) {
                $('#searchPhraseRefinementsSidebar').css('overflow-y', 'auto');
            }
        }
    });

    $(window).on('load', function () {
        changeLabelSearch();
    });

    $('.js-search-field').on('focus', function () {
       if (!window.isMobile()) {
           $('.js-icon-search-bar').addClass('hide');
       }
    });

    $('.js-search-field').on('blur', function () {
        var inputVal = $(this).val();
        if (!window.isMobile() && inputVal.length === 0) {
            $('.js-icon-search-bar').removeClass('hide');
        }
    });

    $('.js-form-search').on('keyup', '.js-search-field', function (e) {
        var input = $(this);
        var inputValue = input.val();

        input.addClass('keyup-color');
        // Check if the user has typed at least 3 characters
        if (inputValue.length > 2) {
            var debounceSuggestions = debounce(getSuggestions, 300);
            debounceSuggestions(this, e);
        }

        if (inputValue.length === 0) {
            clearSuggestions();
            input.removeClass('keyup-color');
        }

        if (e.key === "Enter") {
            $(this).blur(); // Remove focus
        }
    });

    $('body').on('click', '.js-btn-suggestion-phrases', function () {
        var btnSuggestionPhrases = $(this);
        var inputSearchField = $('.js-search-field');
        inputSearchField.val(btnSuggestionPhrases.data('phrases'));
        inputSearchField.trigger('keyup');
    });

    $('.js-reset-button-search').on('click', function () {
        clearSuggestions();
    });

    $('body').on('shown.bs.modal', '#searchPhraseRefinementsSidebar' ,function () {
        if (window.isMobile()) {
          $('#searchPhraseRefinementsSidebar').css('overflow-y', 'hidden');
        }
    });

    $('body').on('hidden.bs.modal', '#searchPhraseRefinementsSidebar' ,function () {
        if (!window.isMobile()) {
            if($('.js-modal-search').hasClass('show')) {
                $('body').addClass('modal-open');
            }
        }
    });

    $('.js-modal-search').on('hidden.bs.modal', function (e) {
        e.preventDefault();
        clearSuggestions();
    });
};
