'use strict'

const cache = {
    $body: $('body'),

    $activeObscure: $('.active-obscure'),

    $columnActiveObscure: $('.col-active-obscure'),

    $logoMobile: $('.logo-header')
}

function obscureImage() {
    let imageSelected = $(this);
    let imageToObscureContainer;
    let imageToObscure;

    if (cache.$activeObscure.length) {
        if (imageSelected.hasClass('first-col-pd')) {
            imageToObscureContainer = cache.$body.find('.second-col-pd');
        } else {
            imageToObscureContainer = cache.$body.find('.first-col-pd');
        }

        imageToObscure = imageToObscureContainer.find('.image-pd');
    }

    imageToObscure.addClass('obscure-image');
    imageToObscureContainer.find('.editorial-elements').addClass('d-none');
}

function resetImage() {
    let firstColPD = cache.$body.find('.obscure-image').closest('.first-col-pd');
    if (firstColPD.length !== 0) {
        firstColPD.find('.editorial-elements').removeClass('d-none');
    } else {
        cache.$body.find('.obscure-image').closest('.second-col-pd').find('.editorial-elements').removeClass('d-none');
    }

    cache.$body.find('.obscure-image').removeClass('obscure-image');
}

cache.$columnActiveObscure.on('mouseover', obscureImage);
cache.$columnActiveObscure.on('mouseout', resetImage);
