'use strict';

const TrackingEvents = require('sky_datalayer/tracking/trackingEvents');
const EventHandlers = require('./eventHandlers');
const Tracking = require('./tracking');

class TrackingEventsPupa extends TrackingEvents {
    static tracking = new Tracking();
    static eventHandlers = EventHandlers;
}

module.exports = TrackingEventsPupa;

