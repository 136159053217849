'use strict';

const cache = {
    $selectorSwiperProduct : '.js-swiper-tutorial-description',
};

$(window).on('load', function () {
    initSwiperProduct();
});

function initSwiperProduct() {
    const swiperProduct = new Swiper(cache.$selectorSwiperProduct, {
        modules: [Autoplay, Navigation, Pagination, Scrollbar],
        slidesPerView: 3,
        spaceBetween: 28,
        autoHeight: true,
        loop: true,
        pagination: {
            el: '.swiper-pagination',
            type: 'fraction'
        },
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
        breakpoints: {
            0: {
                slidesPerView: 1.5,
            },
            768: {
                slidesPerView: 2,
            },
            1200: {
                slidesPerView: 2,
            },
            1400: {
                slidesPerView: 3,
            },
        },
    });
}
