$(document).ready(function () {
    const sliders = $('.js-drag');

    if (sliders.length > 0) {
        sliders.each(function(index, element) {
            let isDown = false;
            let startX;
            let scrollLeft;

            const slider = $(element);

            element.addEventListener('mousedown', (e) => {
                isDown = true;
                startX = e.pageX - slider.offset().left;
                scrollLeft = slider.scrollLeft();
            });

            element.addEventListener('mouseleave', () => {
                isDown = false;
            });

            element.addEventListener('mouseup', () => {
                isDown = false;
            });

            element.addEventListener('mousemove', (e) => {
                if(!isDown) return;
                e.preventDefault();
                const x = e.pageX - slider.offset().left;
                const walk = (x - startX) * 1;
                slider.scrollLeft(scrollLeft - walk);
            });
        });
    }
});
