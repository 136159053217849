'use strict';

var base = require('base/product/base');
const eventHandlers = require('../tracking/eventHandlers');
const eventsType = eventHandlers.eventsType;


/**
 * Retrieve contextual quantity selector
 * @param {jquery} $el - DOM container for the relevant quantity
 * @return {jquery} - quantity selector DOM container
 */
function getQuantitySelector(addCartBtn) {
    if(addCartBtn.closest('.product-set').length > 0 && $('.set-items').length > 0) {
        var quantityValueSet = addCartBtn.closest('.product-set').find('.js-quantity-value');
        return quantityValueSet.data('value-quantity');
    }

    if(cache.$quantityValue.length > 0) {
        return cache.$quantityValue.data('value-quantity');
    }

    return 1;
}

/**
 * Parses the html for a modal window
 * @param {string} html - representing the body and footer of the modal window
 *
 * @return {Object} - Object with properties body and footer.
 */
function parseHtml(html) {
    var $html = $('<div>').append($.parseHTML(html));

    var body = $html.find('.choice-of-bonus-product');
    var footer = $html.find('.modal-footer').children();

    return { body: body, footer: footer };
}

/**
 * Retrieves url to use when adding a product to the cart
 *
 * @param {Object} data - data object used to fill in dynamic portions of the html
 */
function chooseBonusProducts(data) {
    $.spinner().start();

    if ($('#chooseBonusProductModal').length !== 0) {
        $('#chooseBonusProductModal').remove();
    }

    var bonusUrl;
    if (data.bonusChoiceRuleBased) {
        bonusUrl = data.showProductsUrlRuleBased;
    } else {
        bonusUrl = data.showProductsUrlListBased;
    }

    var htmlString = '<!-- Modal -->'
        + '<div class="modal fade choose-bonus-product-modal js-choose-bonus-product-modal sm-full" id="chooseBonusProductModal" tabindex="-1" role="dialog">'
        + '<span class="enter-message sr-only" ></span>'
        + '<div class="modal-dialog modal-xl modal-dialog-centered choose-bonus-product-dialog" '
        + 'data-total-qty="' + data.maxBonusItems + '"'
        + 'data-UUID="' + data.uuid + '"'
        + 'data-pliUUID="' + data.pliUUID + '"'
        + 'data-addToCartUrl="' + data.addToCartUrl + '"'
        + 'data-pageStart="0"'
        + 'data-pageSize="' + data.pageSize + '"'
        + 'data-moreURL="' + data.showProductsUrlRuleBased + '"'
        + 'data-bonusChoiceRuleBased="' + data.bonusChoiceRuleBased + '">'
        + '<!-- Modal content-->'
        + '<div class="modal-content">'
        + '<div class="modal-header">'
        + '    <div class="modal-title text-uppercase">' + data.label + '</div>'
        + '    <button type="button" class="close" data-dismiss="modal">'
        + '        <span class="icon icon-close d-none d-md-block" aria-hidden="true"></span>'
        + '        <span class="icon icon-arrow-right d-block d-md-none"></span>'
        + '    </button>'
        + '</div>'
        + '<div class="modal-body">'
        + '     <div class="row row-body"></div> '
        + '</div>'
        + '<div class="modal-footer"></div>'
        + '</div>'
        + '</div>'
        + '</div>';
    $('body').append(htmlString);

    $.ajax({
        url: bonusUrl,
        method: 'GET',
        dataType: 'json',
        success: function (response) {
            var parsedHtml = parseHtml(response.renderedTemplate);
            $('#chooseBonusProductModal .row-body').empty();
            $('#chooseBonusProductModal .enter-message').text(response.enterDialogMessage);
            $('#chooseBonusProductModal .modal-header .close .sr-only').text(response.closeButtonText);
            $('#chooseBonusProductModal .row-body').html(parsedHtml.body);
            $('#chooseBonusProductModal .modal-footer').html(parsedHtml.footer);
            $('#chooseBonusProductModal').modal('show');

            $.spinner().stop();
        },
        error: function () {
            $.spinner().stop();
        }
    });
}

/**
 * Updates the Mini-Cart quantity value after the customer has pressed the "Add to Cart" button
 * @param {string} response - ajax response from clicking the add to cart button
 */
function handlePostCartAdd(response) {
    if (!response.error) {
        $('.minicart').trigger('count:update', response);

        // show minicart
        let url = $('.minicart-cta').data('action-url');

        $('.minicart-quantity').empty().append(response.quantityTotal);

        if (response.quantityTotal !== 0) {
            $.get(url, function (data) {
                $('.js-minicart').find('.modal-body')[0].innerHTML = data;
                $('.js-minicart').find('.number-of-items')[0].innerHTML = '(' + response.quantityTotal + ')';

                let action = $('.page').data('action');
                if (action === 'Cart-Show') {
                    location.reload();
                } else {
                    openMinicart();
                }

                $.spinner().stop();
            });
        }
    } else {
        if (response.message) {
            let title = $('button.js-add-to-cart-pdp, button.js-btn-add-cart-tile, button.js-btn-quickView-add-cart').data('title');
            $(document).trigger('setToastMessage', [title, response.message, false]);
        }
    }

    $('#searchModal').modal('hide');
}

/**
 * Makes a call to the server to report the event of adding an item to the cart
 *
 * @param {string | boolean} url - a string representing the end point to hit so that the event can be recorded, or false
 */
function miniCartReportingUrl(url) {
    if (url) {
        $.ajax({
            url: url,
            method: 'GET',
            success: function () {
                // reporting urls hit on the server
            },
            error: function () {
                // no reporting urls hit on the server
            }
        });
    }
}

const cache = {
    $body: $('body'),
    $quantityValue: $('.js-quantity-value')
};

function openMinicart() {
    $('#minicartSidebar').modal('show');
}

function getOptions($productContainer) {
    var options = $productContainer
        .find('.product-option')
        .map(function () {
            var $elOption = $(this).find('.options-select');
            var urlValue = $elOption.val();
            var selectedValueId = $elOption.find('option[value="' + urlValue + '"]').data('value-id');
            return {
                optionId: $(this).data('option-id'),
                selectedValueId: selectedValueId
            };
        }).toArray();

    return JSON.stringify(options);
}

base.addToCart = function () {
    $(document).on('click', 'button.js-add-to-cart-pdp, button.js-btn-add-cart-tile, button.js-btn-quickView-add-cart, button.add-to-cart-global', function () {
        var addToCartUrl;
        var pid;
        var pidsObj;
        var setPids;
        var cartTitle;
        var addToCartError;
        var className =  this.className;

        if ($('.set-items').length && $(this).hasClass('add-to-cart-global')) {
            setPids = [];

            $('.product-detail').each(function () {
                if (!$(this).hasClass('product-set-detail')) {
                    setPids.push({
                        pid: $(this).data('pid'),
                        qty: $(this).find('.js-quantity-value').text(),
                        options: getOptions($(this))
                    });
                }
            });
            pidsObj = JSON.stringify(setPids);
        }

        $('body').trigger('product:beforeAddToCart', this);
        pid = $(this).data('pid');
        cartTitle = $(this).data('title');
        addToCartError = $(this).data('add-to-cart-error');
        addToCartUrl = $('.add-to-cart-url').val();

        var form = {
            pid: pid,
            pidsObj: pidsObj,
            childProducts: [],
            quantity: getQuantitySelector($(this)),
            className: className
        };
        $(this).trigger('updateAddToCartFormData', form);
        if (addToCartUrl) {
            $.ajax({
                url: addToCartUrl,
                method: 'POST',
                data: form,
                success: function (data) {
                    $('body').trigger('product:afterAddToCart', data);
                    handlePostCartAdd(data);
                    $.spinner().stop();
                    miniCartReportingUrl(data.reportingURL);
                    if(Object.keys(data.trackingProduct).length !== 0) {
                        eventHandlers.sendEvent($(data.trackingProduct), eventsType.addToCart);
                    }
                },
                error: function (err) {
                    $.spinner().stop();
                    $(document).trigger('setToastMessage', [cartTitle, addToCartError, false]);
                    if (err.responseJSON && err.responseJSON.redirectUrl) {
                        window.location.href = err.responseJSON.redirectUrl;
                    }
                }
            });
        }
    });
};

base.methods = function (data) {
    chooseBonusProducts(data);
};

function getSelectedProducts(modalDialog, valueToAdd) {
    let selectedProducts = 0;

    modalDialog.find('.js-selected-product').each(function () {
        selectedProducts += parseInt($(this).closest('.js-bonus-product').find('.js-quantity-value')[0].innerText);
    });

    return selectedProducts + valueToAdd;
}

base.selectBonusProduct = function () {
    $(document).on('click', '.js-not-selected-product', function () {
        var productSelected = $(this);

        let modalDialog = productSelected.closest('.choose-bonus-product-dialog');
        let maxPids = modalDialog.data('total-qty');
        let selectedProducts = getSelectedProducts(modalDialog, 1);

        if (selectedProducts <= maxPids) {
            productSelected.removeClass('js-not-selected-product');
            productSelected.addClass('js-selected-product');

            if (selectedProducts === maxPids) {
                modalDialog.find('.select-bonus-product:not(:checked)').prop('disabled', true);
                modalDialog.find('.js-btn-add-bonus-quantity').prop('disabled', true);
            } else {
                productSelected.closest('.js-bonus-product').find('.js-bonus-quantity').find('.js-btn-add-bonus-quantity').prop('disabled', false);
            }
            productSelected.closest('.js-bonus-product').find('.js-bonus-quantity').removeClass('disabled');
        }

        // in any case in the initial phase the counter is initialized to zero and cannot be decreased
        productSelected.closest('.js-bonus-product').find('.js-bonus-quantity').find('.js-btn-remove-bonus-quantity').prop('disabled', true);
    });
};

base.removeBonusProduct =  function () {
    $(document).on('click', '.js-selected-product', function () {
        let productSelected = $(this);
        let productQuantity = productSelected.closest('.js-bonus-product').find('.js-bonus-quantity');

        let modalDialog = productSelected.closest('.choose-bonus-product-dialog');
        let maxPids = modalDialog.data('total-qty');
        let selectedProducts = getSelectedProducts(productSelected.closest('.choose-bonus-product-dialog'), -1);

        if (selectedProducts <= maxPids) {
            modalDialog.find('.select-bonus-product:not(:checked)').removeClass('disabled');
            modalDialog.find('.js-btn-add-bonus-quantity').prop('disabled', false);
        }

        if (productQuantity.length > 0) {
            productQuantity.addClass('disabled');
            var valueQuantity = productQuantity.find('.js-quantity-value');

            if (valueQuantity.length > 0) {
                valueQuantity.data('value-quantity', 1);
                valueQuantity.html('1');
            }
        }

        if (selectedProducts < maxPids) {
            modalDialog.find('.select-bonus-product:not(:checked)').prop('disabled', false);
            modalDialog.find('.select-bonus-product:checked').closest('.js-bonus-product').find('.js-bonus-quantity').removeClass('disabled');
        }

        productSelected.removeClass('js-selected-product');
        productSelected.addClass('js-not-selected-product');
    });
};

base.addBonusProductsToCart = function () {
    $(document).on('click', '.js-add-bonus-products', function () {
        var queryString = '?pids=';
        var urlMiniCart = $('.js-bonus-product-button').data('minicart');
        var url = $('.choose-bonus-product-dialog').data('addtocarturl');
        var pidsObject = {
            bonusProducts: []
        };

        var option = null;

        $('.select-bonus-product:checked').each(function () {
            if ($(this).data('optionID') && $(this).data('option-selected-value')) {
                option = {};
                option.optionId = $(this).data('optionID');
                option.productId = $(this).data('pid');
                option.selectedValueId = $(this).data('option-selected-value');
            }
            pidsObject.bonusProducts.push({
                pid: $(this).data('pid'),
                qty: $(this).closest('.js-bonus-product').find('.js-quantity-value').data('value-quantity'),
                options: [option]
            });
        });

        pidsObject.totalQty = $(this).closest('.choose-bonus-product-dialog').data('total-qty');

        queryString += JSON.stringify(pidsObject);
        queryString = queryString + '&uuid=' + $(this).closest('.choose-bonus-product-dialog').data('uuid');
        queryString = queryString + '&pliuuid=' + $(this).closest('.choose-bonus-product-dialog').data('pliuuid');

        $.spinner().start();

        $.ajax({
            url: url + queryString,
            method: 'POST',
            success: function (data) {
                $.spinner().stop();
                if (!data.success) {
                    $('.js-choose-bonus-product-modal').modal('hide');
                    $(document).trigger('setToastMessage', [data.cartTitle, data.errorMessage, false]);
                } else {
                    $('.js-choose-bonus-product-modal').modal('hide');

                    $(document).trigger('setToastMessage', [data.cartTitle, data.msgSuccess, true]);

                    $('.minicart-quantity').html(data.totalQty);

                    var dataToStore = {
                        url: urlMiniCart,
                        totalQty: data.totalQty
                    };

                    setTimeout(function () {
                        if (urlMiniCart) {
                            localStorage.setItem('reloadFromTimeout', JSON.stringify(dataToStore));
                        }
                        location.reload();
                    }, 2000);
                }
            },
            error: function (err) {
                $.spinner().stop();
                $(document).trigger('setToastMessage', ['', err.responseJSON.message, false]);
            }
        });
    });
};

$(document).on('click', '.js-btn-add-bonus-quantity', function () {
    var btnAddBonusQuantity = $(this);
    var quantityBox = btnAddBonusQuantity.closest('.js-bonus-product').find('.js-bonus-quantity');
    var selectorQuantity = quantityBox.find('.js-quantity-value');
    let selectedProducts = getSelectedProducts(btnAddBonusQuantity.closest('.choose-bonus-product-dialog'), 1);

    let modalDialog = btnAddBonusQuantity.closest('.choose-bonus-product-dialog');
    let maxPids = modalDialog.data('total-qty');

    if (selectedProducts <= maxPids) {
        let currentQuantity = selectorQuantity.data('value-quantity');
        let newQuantity = currentQuantity + 1;
        selectorQuantity.data('value-quantity', newQuantity);
        selectorQuantity.html(newQuantity);

        if (selectedProducts === maxPids) {
            modalDialog.find('.select-bonus-product:not(:checked)').prop('disabled', true);

            // disable all increase button
            btnAddBonusQuantity.closest('.choose-bonus-product-dialog').find('.js-btn-add-bonus-quantity').prop('disabled', true);
        }

        quantityBox.find('.js-btn-remove-bonus-quantity').prop('disabled', false);
    }
});

$(document).on('click', '.js-btn-remove-bonus-quantity', function () {
    let btnRemoveBonusQuantity = $(this);
    let quantityBox = btnRemoveBonusQuantity.closest('.js-bonus-product').find('.js-bonus-quantity');
    let selectorQuantity = quantityBox.find('.js-quantity-value');
    let selectedProducts = getSelectedProducts(btnRemoveBonusQuantity.closest('.choose-bonus-product-dialog'), -1);

    let modalDialog = btnRemoveBonusQuantity.closest('.choose-bonus-product-dialog');
    let maxPids = modalDialog.data('total-qty');

    if (selectedProducts <= maxPids) {
        let currentQuantity = selectorQuantity.data('value-quantity');
        let newQuantity = currentQuantity - 1;
        selectorQuantity.data('value-quantity', newQuantity);
        selectorQuantity.html(newQuantity);

        if ((selectedProducts - currentQuantity + 1) >= 0) {
            modalDialog.find('.select-bonus-product:not(:checked)').prop('disabled', false);
            quantityBox.find('.js-btn-add-bonus-quantity').prop('disabled', false);
        }

        if (selectedProducts === 1 || newQuantity === 1) {
            quantityBox.find('.js-btn-remove-bonus-quantity').prop('disabled', true);
        }

        modalDialog.find('.js-btn-add-bonus-quantity').prop('disabled', false);
    }
});

module.exports = base;
