'use strict';

const cache = {
    $form: $('form'),
    $formInput: $('form input'),
    $formInputNoFile: $('form input[type!="file"]'),
    $formInputs: $('form input, form select, textarea'),
    $formCheckboxes: $('form input[type="checkbox"]'),
    $formInputsNoValidOnFocus: $('form input[type!="file"]:not([id*="_birthday"]), form select, textarea'),
    $formInputsNoFile: $('form input[type!="file"], form select, textarea'),
    $formButtonInputSubmit: $('form button[type="submit"], form input[type="submit"]'),
    $inputFile: $('form input[type="file"]'),
    $inputBirthdate: $('[id*= "_birthday"]')
};

/**
 * Validate whole form. Requires `this` to be set to form object
 * @param {jQuery.event} event - Event to be canceled if form is invalid.
 * @param {boolean} invalidate - Set it to True to avoid input invalidation.
 * @returns {boolean} - Flag to indicate if form is valid
 */
function validateForm(event) {
    var valid = true;
    if (this.checkValidity && !this.checkValidity()) {
        // safari
        if (event) {
            event.preventDefault();
            event.stopPropagation();
            event.stopImmediatePropagation();
        }

        $(this).find('input:not([novalidate]), select:not([novalidate])').each(function () {
            if (this.checkValidity) this.checkValidity();
            if (!this.validity.valid) {
                $(this).trigger('invalid', this.validity);
                valid = false;
            } else if (this.attributes && this.attributes.type && this.attributes.type.value === 'file') {
                $(this).trigger('change', this.validity);
            }
        });

        if (typeof $(this).attr('novalidate') !== 'undefined') {
            $(this).addClass('was-validated');
        }
    }
    return valid;
}

/**
 * Remove all validation. Should be called every time before revalidating form
 * @param {element} form - Form to be cleared
 * @returns {void}
 */
function clearForm(form) {
    cache.$form.find('.form-control.is-invalid').removeClass('is-invalid');
}

function eventFormInput() {
    cache.$formInputsNoValidOnFocus.off('keyup focus blur').on('keyup focus blur', function () {
        $(this).removeClass('is-invalid');
        $(this).trigger('invalid', this.validity);
    });
}

function birthdateValidation() {
    if (cache.$inputBirthdate[0].value) {
        cache.$inputBirthdate.removeClass('is-invalid');

        let validationMessage = '';
        let formattingDate = cache.$inputBirthdate[0].value.split('-').join('/');
        let birthDate = new Date(formattingDate);

        // check birthdate
        let dateAfter16Years = new Date(birthDate);
        dateAfter16Years.setFullYear(dateAfter16Years.getFullYear() + 16);

        let currentDate = new Date();

        if (currentDate < dateAfter16Years) {
            // it hasn't been 16 years
            validationMessage = cache.$inputBirthdate[0].dataset.rangeError;
        }

        if (validationMessage !== '') {
            cache.$inputBirthdate.addClass('is-invalid');
            cache.$inputBirthdate.parents('.form-group').find('.invalid-feedback')
                .text(validationMessage);

            return false;
        }
    }
}

module.exports = {

    validateForm: validateForm,

    clearForm: clearForm,

    invalid: function () {
        cache.$formInputs.on('invalid', function (e) {
            e.preventDefault();
            this.setCustomValidity('');
            if (!this.validity.valid) {
                let validationMessage = this.validationMessage;
                $(this).addClass('is-invalid');

                if (this.validity.patternMismatch && $(this).data('pattern-mismatch')) {
                    validationMessage = $(this).data('pattern-mismatch');
                }
                if ((this.validity.rangeOverflow || this.validity.rangeUnderflow) && $(this).data('range-error')) {
                    validationMessage = $(this).data('range-error');
                }
                if ((this.validity.tooLong || this.validity.tooShort) && $(this).data('range-error')) {
                    validationMessage = $(this).data('range-error');
                }
                if (this.validity.valueMissing && $(this).data('missing-error')) {
                    validationMessage = $(this).data('missing-error');
                }
                if (this.validity.patternMismatch && $(this).data('parse-error')) {
                    validationMessage = $(this).data('parse-error');
                }

                $(this).parents('.form-group').find('.invalid-feedback')
                    .text(validationMessage);
            }

            // the custom validation of the birthday date must be carried out even if any other field is invalid
            if (cache.$inputBirthdate.length > 0) {
                birthdateValidation();
            }
        });
    },

    validateBirthdate: function () {
        $('form').submit(function() {
            cache.$inputBirthdate.on('change blur focusout', function (e) {
                birthdateValidation();
            });
        });
    },

    validateFileSize: function () {
        cache.$inputFile.on('click', function () {
            this.value = null;
        });

        cache.$inputFile.on('change blur focusout', function () {
            cache.$inputFile.removeClass('is-invalid');

            let maxSize = cache.$inputFile.data("maxsize");
            let validationMessage = '';
            if (cache.$inputFile[0].files.length > 0) {
                if (!maxSize) {
                    return true;
                }

                var fileSize = cache.$inputFile[0].files[0].size;
                if (fileSize && fileSize <= maxSize) {
                    return true;
                } else {
                    validationMessage = $(this).data('pattern-mismatch');
                }
            } else {
                if (!this.validity.valid) {
                    if (this.validity.valueMissing && $(this).data('missing-error')) {
                        validationMessage = $(this).data('missing-error');
                    }
                }
            }

            if (validationMessage !== '') {
                cache.$inputFile.addClass('is-invalid');
                $(this).parents('.form-group').find('.invalid-feedback')
                    .text(validationMessage);

                return false;
            }
        });
    },

    focus: function () {
        cache.$formInputsNoFile.on('focus', function () {
            $(this).removeClass('is-invalid');
        });
    },

    submit: function () {
        cache.$form.on('submit', function (e) {
            eventFormInput();
            return validateForm.call(this, e);
        });
    },

    buttonClick: function () {
        cache.$formButtonInputSubmit.on('click', function () {
            // clear all errors when trying to submit the form
            clearForm($(this).parents('form'));
        });
    },

    checkbox: function() {
        cache.$formCheckboxes.on('change', function() {
            $(this).removeClass('is-invalid');
            $(this).trigger('invalid', this.validity);
        });
    },

    init: function() {
        cache.$form.each((i, form) => {
            let validationStartEvent = $(form).data('validate');
            if (validationStartEvent) {
                $(form).find('input[type!="file"]').on(validationStartEvent, function() {
                    $(this).removeClass('is-invalid');
                    $(this).trigger('invalid', this.validity);
                });
            }

            $(form).find('input[type="tel"]').on('focusout', function() {
                $(this).removeClass('is-invalid');
                $(this).trigger('invalid', this.validity);
            });
        });


        $.extend( $.fn, {
            validateForm: function(startActiveCheck) {
                if (this.length === 0) {
                    return null;
                }
                let elem = this[0];
                if (startActiveCheck) {
                    eventFormInput();
                }
                return validateForm.call(elem, null);
            }
        });

    },

    functions: {
        validateForm: function (form, event) {
            validateForm.call($(form), event || null);
        },
        clearForm: clearForm
    }
};
