'use strict';

const cache = {
    $cardPD : $('.js-pd-card'),
};

cache.$cardPD.on('mouseleave', function () {
    $(this).find('.js-pd-card-body').addClass('leave-hover');
});

cache.$cardPD.on('mouseenter', function () {
    $(this).find('.js-pd-card-body').removeClass('leave-hover');
});
