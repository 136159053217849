'use strict';

const eventHandlers = require('../tracking/eventHandlers');
const eventsType = eventHandlers.eventsType;


function checkArrow(selectorSwiper) {
    var swiperPrev = selectorSwiper.find('.swiper-button-prev');
    var swiperNext = selectorSwiper.find('.swiper-button-next');
    var lengthSlide = selectorSwiper.find('.swiper-slide');

    if (lengthSlide.length === swiperRecProduct.params.slidesPerView) {
        swiperPrev.addClass('swiper-button-lock');
        swiperNext.addClass('swiper-button-lock');
    }
}

var swiperRecProduct;

module.exports = {
    initCarouselRecommendation: function () {
        swiperRecProduct = new Swiper('.js-slot-swiper-rec-product', {
            modules: [Autoplay, Navigation, Pagination, Scrollbar],
            slidesPerView: 4,
            spaceBetween: 28,
            autoHeight: true,
            pagination: {
                el: '.swiper-pagination',
                type: 'fraction'
            },
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
            breakpoints: {
                0: {
                    slidesPerView: 1,
                },
                576: {
                    slidesPerView: 2,
                },
                992: {
                    slidesPerView: 3,
                },
                1200: {
                    slidesPerView: 4,
                },
            },
            on: {
                resize: function () {
                    checkArrow($('.js-slot-swiper-rec-product'));
                },
            }
        });
        checkArrow($('.js-slot-swiper-rec-product'));
        var infoItems = $('.js-slot-swiper-rec-product').find('.js-info-tracking');
        if(infoItems.length > 0) {
            eventHandlers.sendEvent(infoItems, eventsType.viewItemList);
        }
    },

    initCarouselHighlightedProduct: function () {
        const swiperHighlightedProduct = new Swiper('.js-slot-swiper-search-highlighted', {
            modules: [Autoplay, Navigation, Pagination, Scrollbar],
            slidesPerView: 3,
            spaceBetween: 28,
            autoHeight: true,
            breakpoints: {
                0: {
                    slidesPerView: 2.5
                },
                576: {
                    slidesPerView: 3
                },
                768: {
                    slidesPerView: 3
                },
                1200: {
                    slidesPerView: 3
                }
            }
        });
    }
};
