'use strict';

const Tracking = require('./tracking');
const eventHandlers = require('./eventHandlers');

class TrackingEvents {
    static eventHandlers = new eventHandlers();
    static action = $('.page').data('action');
    static tracking = new Tracking();

    static trackingHandler() {
        var self = this;
        self.supportedEvents = self.eventHandlers.getSupportedEvents(this.action);
        if(this.supportedEvents && this.supportedEvents.length > 0) {
            this.supportedEvents.forEach(function(trackingEvent) {
                $('body').on('Tracking:'+ trackingEvent.replaceAll('_', ''), (event, data) => {
                    data.event = trackingEvent;
                    self.tracking.trackingImpression(data);
                });
            });
        }
    }
}

module.exports = TrackingEvents;
