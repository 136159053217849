'use strict';

var formValidation = require('../components/formValidation');

var cache = {
    //Newsletter Popup
    $modalNewsletter: $('.js-newsletterModal'),
    $formNewsletter: $('.js-newsletter-form-popup'),
    $errorMessage: $('.js-error-message-newsletter'),
    $successMessage: $('.js-newsletter-success'),

    //Newsletter Page Editorial
    $formNewsletterEditorial: $('.js-nl-subscribe'),
    $errorMessageEditorial: $('.js-error-message-newsletter-editorial'),
    $successMessageEditorial: $('.js-newsletter-success-editorial'),

    //Newsletter Lading Page
    $formNewsletterLadingPage: $('.js-nl-landing-page'),
    $errorMessageLadingPage: $('.js-error-message-nl-landing-page'),
    $successMessageLadingPage: $('.js-nl-success-landing-page'),

    //Newsletter Footer
    $formNewsletterFooter: $('.js-nl-subscribe-footer'),
    $errorMessageFooter: $('.js-error-message-newsletter-footer'),
    $successMessageFooter: $('.js-newsletter-success-footer')
};

$(window).scroll(function () {
    var scrollPosition = $(this).scrollTop();
    if (localStorage.getItem('popupShownNewsletter') === null && scrollPosition >= 500) {
        cache.$modalNewsletter.modal('show');
    }
});

cache.$formNewsletter.on('submit', function (e) {
    submitFormNewsletter(e, cache.$formNewsletter, cache.$errorMessage, cache.$successMessage);
});

cache.$formNewsletterEditorial.on('submit', function (e) {
    submitFormNewsletter(e, cache.$formNewsletterEditorial, cache.$errorMessageEditorial, cache.$successMessageEditorial);
});

cache.$formNewsletterLadingPage.on('submit', function (e) {
    submitFormNewsletter(e, cache.$formNewsletterLadingPage, cache.$errorMessageLadingPage, cache.$successMessageLadingPage);
});

cache.$formNewsletterFooter.on('submit', function (e) {
    submitFormNewsletter(e, cache.$formNewsletterFooter, cache.$errorMessageFooter, cache.$successMessageFooter);
});

cache.$modalNewsletter.on('hidden.bs.modal', function () {
    localStorage.setItem('popupShownNewsletter', 'true');
});

function submitFormNewsletter(e, formSelector, errorSelector, successSelector) {
    var $form = $(formSelector);
    var contentForm = $form.closest('.newsletter-form');
    e.preventDefault();
    var url = $form.attr('action');
    $form.spinner().start();
    $.ajax({
        url: url,
        type: 'post',
        dataType: 'json',
        data: $form.serialize(),
        success: function (data) {
            $form.spinner().stop();
            if (!data.success) {
                formValidation($form, data);
                errorSelector.text(data.message);
                errorSelector.removeClass('hide');

                if (contentForm.length > 0) {
                    contentForm.css('flex-grow', 0.5);
                }

            } else {
                formSelector.addClass('hide');
                successSelector.removeClass('hide');
            }
        },
        error: function (err) {
            errorSelector.text(err.responseJSON.message);
            errorSelector.removeClass('hide');
            $form.spinner().stop();
        }
    });
    return false;
}
