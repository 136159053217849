'use strict';

var focusHelper = require('../components/focus');

/**
 * Generates the modal window on the first call.
 *
 */
function getModalHtmlElement() {
    if ($('#quickViewModal').length !== 0) {
        $('#quickViewModal').remove();
    }
    var htmlString = '<!-- Modal -->'
        + '<div class="modal fade right-sidebar" id="quickViewModal" role="dialog">'
        + '<div class="modal-dialog modal-dialog-width quick-view-dialog">'
        + '<!-- Modal content-->'
        + '<div class="modal-content">'
        + '<div class="modal-header">'
        + '    <h3 class="heading-title full-pdp-link m-0 text-uppercase text-bold"></h3>'
        + '    <button type="button" class="close" data-dismiss="modal" aria-label="Close">'
        + '        <span class="icon icon-close p-0" aria-hidden="true"></span>'
        + '    </button>'
        + '</div>'
        + '<div class="modal-body custom-scrollbar p-0"></div>'
        + '<div class="modal-footer"></div>'
        + '</div>'
        + '</div>'
        + '</div>';
    $('body').append(htmlString);
}

/**
 * @typedef {Object} QuickViewHtml
 * @property {string} body - Main Quick View body
 * @property {string} footer - Quick View footer content
 */

/**
 * Parse HTML code in Ajax response
 *
 * @param {string} html - Rendered HTML from quickview template
 * @return {QuickViewHtml} - QuickView content components
 */
function parseHtml(html) {
    var $html = $('<div>').append($.parseHTML(html));

    var body = $html.find('.product-quickview');
    var footer = $html.find('.modal-footer').children();

    return { body: body, footer: footer };
}

/**
 * replaces the content in the modal window on for the selected product variation.
 * @param {string} selectedValueUrl - url to be used to retrieve a new product model
 */
function fillModalElement(selectedValueUrl) {
    let $quickViewModal = $('#quickViewModal');
    let $quickViewModalBody = $quickViewModal.find('.modal-body');
    let $quickViewModalFooter = $quickViewModal.find('.modal-footer');
    $.spinner().start();
    $.ajax({
        url: selectedValueUrl,
        method: 'GET',
        dataType: 'json',
        success: function (data) {
            let card = $('.product-' + data.product.id);
            let selectedVariant = card.find('.js-btn-variant-product-tile.selected');
            let colorIdVariant = selectedVariant.data('variant-color-id');
            let parsedHtml = parseHtml(data.renderedTemplate);
            $quickViewModal.attr('data-pid', data.product.id);
            $quickViewModalBody.empty();
            $quickViewModalBody.html(parsedHtml.body);
            $quickViewModalFooter.html(parsedHtml.footer);
            $('.full-pdp-link').text(data.product.productName);
            $quickViewModal.find('.modal-header .close .sr-only').text(data.closeButtonText);
            $quickViewModal.find('.collapsed').removeClass('collapsed');
            let collapse = $quickViewModal.find('.js-collapse-variants');
            collapse.addClass('show');
            let variantCollapse = collapse.find('[data-attr-value=' + colorIdVariant + ']');
            variantCollapse.addClass('selectedQuickView');
            variantCollapse.click(); // click is useful to check product availability
            $quickViewModal.find('.js-btn-quickView-add-cart').data('pid', variantCollapse.data('attr-variant-id'));
            $quickViewModal.modal('show');
            $('body').trigger('quickview:ready');

            $.spinner().stop();
        },
        error: function () {
            $.spinner().stop();
        }
    });
}

function activeHover(card) {
    if (card.length > 0) {
        if (!window.isMobile()) {
            card.addClass('hover-active');
        } else {
            card.removeClass('hover-active');
        }
    }
}

module.exports = {
    showQuickview: function () {
        $('body').on('click', '.quickview', function (e) {
            e.preventDefault();
            var selectedValueUrl = $(this).closest('a.quickview').data('quickview-url');
            activeHover($(this).closest('.card[class*="product-"]'));

            $(e.target).trigger('quickview:show');

            getModalHtmlElement();
            fillModalElement(selectedValueUrl);
        });
    },

    closeQuickview: function () {
        $('body').on('hidden.bs.modal', '#quickViewModal' ,function (e) {
            e.preventDefault();
            $('.card[class*="product-"]').removeClass('hover-active');
        });
    },

    focusQuickview: function () {
        $('body').on('shown.bs.modal', '#quickViewModal', function () {
            $('#quickViewModal .close').focus();
        });
    },

    trapQuickviewFocus: function () {
        $('body').on('keydown', '#quickViewModal', function (e) {
            let focusParams = {
                event: e,
                containerSelector: '#quickViewModal',
                firstElementSelector: '.full-pdp-link',
                lastElementSelector: '.add-to-cart-global',
                nextToLastElementSelector: '.modal-footer .quantity-select'
            };
            focusHelper.setTabNextFocus(focusParams);
        });
    },

    showSpinner: function () {
        $('body').on('product:beforeAddToCart', function (e, data) {
            $(data).closest('.modal-content').spinner().start();
        });
    },

    hideDialog: function () {
        $('body').on('product:afterAddToCart', function () {
            $('#quickViewModal').modal('hide');
        });
    },
};
