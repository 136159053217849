'use strict';

const eventHandlers = require('../tracking/eventHandlers');
const eventsType = eventHandlers.eventsType;

const cache = {
    $body: $('body'),
    $placeholderSmall: $('input[name="noimage_placeholder_small"]'),
    $placeholderMedium: $('input[name="noimage_placeholder_medium"]'),
    $placeholderLarge: $('input[name="noimage_placeholder_large"]'),
    $cardProductImage: $('.js-card-product-image')
}

function replacePrice(price, selector) {
    var selectorPrice = selector.closest('.card-body').find('.price');

    selectorPrice.find('.sales').find('.value').html(price.sales.formatted);
    selectorPrice.find('.sales').find('.value').attr('content', price.sales.value);

    if (price.list) {
        selectorPrice.find('.strike-through').find('.value').find('.sr-only').html(price.list.formatted);
        selectorPrice.find('.strike-through').find('.value').attr('content', price.list.value);
    }
}

function replaceImg(medium, selector, id, vimeoPreviewID) {
    var selectorImageTile, parentImgTile, container, $newImage, oldUrl, newUrl, $productLink;
    var selectCard = selector.closest('.card');
    selectorImageTile = selectCard.find('.images-product');

    if (!vimeoPreviewID) {
        if (selectorImageTile.find('.js-image-product').length > 0) {
            container = selectorImageTile.find('.js-card-product-image');
            oldUrl = container.attr('href');
            newUrl = oldUrl.replace(/pid=[^&]*/, "pid=" + id);
            container.attr('href', newUrl);

            if (medium && medium.length !== 0) {
                $newImage = $('<picture class="js-image-product"><img class="image-product" src="' + medium[0].url + '"' +
                    'title="' + medium[0].title + '" alt="' + medium[0].alt + '"></picture>');
            } else {
                $newImage = $('<picture class="js-image-product"><img class="image-product" src="' + cache.$placeholderMedium[0].value + '"' +
                    'title="PUPA Milano" alt="PUPA Milano"></picture>');
            }

            selectorImageTile.find('.js-image-product').remove();
            container.append($newImage);
        } else {
            container = selectorImageTile.find('.js-card-product-image');
            if (container.length > 0) {
                oldUrl = container.attr('href');
                newUrl = oldUrl.replace(/pid=[^&]*/, "pid=" + id);

                container.remove();

                $productLink = $('<a class="js-card-product-image" href="'+ newUrl +'"></a>');

                if (medium && medium.length !== 0) {
                    $newImage = $('<picture class="js-image-product"><img class="image-product" src="' + medium[0].url + '"' +
                        'title="' + medium[0].title + '" alt="' + medium[0].alt + '"></picture>');
                } else {
                    $newImage = $('<picture class="js-image-product"><img class="image-product" src="' + cache.$placeholderMedium[0].value + '"' +
                        'title="PUPA Milano" alt="PUPA Milano"></picture>');
                }

                $productLink.append($newImage);
                selectorImageTile.append($productLink);
            }
        }
    } else {
        parentImgTile = selectorImageTile.find('.js-card-product-image');
        oldUrl = parentImgTile.attr('href');
        newUrl = oldUrl.replace(/pid=[^&]*/, "pid=" + id);

        parentImgTile.remove();

        $productLink = $('<div class="js-card-product-image js-card-product-image-tile" href="'+ newUrl +'" data-url="' + newUrl + '"></div>'); //dopo metto href

        var $vimeoIframe = $('<iframe class="vimeo-iframe" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen ' +
            'style="position:absolute;top:0;left:0;width:100%;height:100%;pointer-events:none;"></iframe>');

        $vimeoIframe.attr('src', 'https://player.vimeo.com/video/' + vimeoPreviewID + '?controls=0&color=e60000&autoplay=1&loop=1&muted=1&title=0&byline=0&portrait=0&dnt=1');

        var $iframeOverlay = $('<a class="iframe-overlay js-iframe-overlay" href="' + newUrl + '" ' +
            'style="position: absolute; top: 0; left: 0; width: 100%; height: 100%; cursor: pointer;"></a>');

        $productLink.append($vimeoIframe, $iframeOverlay);
        selectorImageTile.append($productLink);
    }
}

function replaceTileBtnData(data, selector) {
    let btnAddCartTile = selector.closest('.card').find('.btn-add-cart');

    if (btnAddCartTile.data('pid')) {
        btnAddCartTile.attr('data-pid', data.product.id);
    }

    if (btnAddCartTile.data('quickview-url')) {
        btnAddCartTile.attr('data-quickview-url', data.quickViewUrl);
    }

    if (btnAddCartTile.attr('href')) {
        btnAddCartTile.attr('href', data.productUrl);
    }
}

function updateWishlist(isInWishlist, id, selector) {
    let wishListHeart = selector.closest('.card').find('.js-wishlist-tile');

    if (!isInWishlist) {
        if (wishListHeart.length > 0) {
            wishListHeart.data('pid', id);
            wishListHeart.addClass('icon-heart js-add-to-wishlist').removeClass('icon-heart-full js-remove-from-wishlist');
            let oldUrl = wishListHeart.data('remove-from-wishlist');
            let newUrl = oldUrl.replace(/pid=[^&]*/, "pid=" + id);
            wishListHeart.data('remove-from-wishlist', newUrl);
        }
    } else {
        if (wishListHeart.length > 0) {
            wishListHeart.data('pid', id);
            wishListHeart.addClass('icon-heart-full js-remove-from-wishlist').removeClass('icon-heart js-add-to-wishlist');
            // update removal url with selected PID
            let oldUrl = wishListHeart.data('remove-from-wishlist');
            let newUrl = oldUrl.replace(/pid=[^&]*/, "pid=" + id);
            wishListHeart.data('remove-from-wishlist', newUrl);
        }
    }
}

function updatePDPUrl(data, selector) {
    let cardProduct = selector.closest('.card').find('.js-card-product-image');

    if (cardProduct.attr('href')) {
        cardProduct.attr('href', data.productUrl);
    }
}

function updateTrackingInfoTemplate(data, selector) {
    if (data.trackingInfoTemplate) {
        let cardProduct = selector.closest('.card').find('.js-info-tracking');
        cardProduct.replaceWith(data.trackingInfoTemplate);
    }
}

$(document).ready(function () {
    if(cache.$cardProductImage.length > 0) {
        cache.$cardProductImage.on('click', function() {
            var infoItems = $(this).find('.js-info-tracking');
            if (infoItems.length > 0) {
                eventHandlers.sendEvent(infoItems, eventsType.selectItem);
            }
        });
    }
});

module.exports = {
    getVariantProductTile: function () {
        $('body').on('click', '.js-btn-variant-product-tile', function () {
            var variantSelected = $(this);

            let parent = $(this).closest('.variation-color');
            parent.find('.js-btn-variant-product-tile').not(this).removeClass('selected');
            $(this).addClass('selected');
            var urlVariantSelect = $(this).data('variant-url');
            $.ajax({
                url: urlVariantSelect,
                type: 'GET',
                dataType: 'json',
                data:'json',
                success: function (data) {
                    replacePrice(data.product.price, variantSelected);
                    replaceImg(data.product.images.medium, variantSelected, data.product.id, data.product.vimeoPreviewID);
                    replaceTileBtnData(data, variantSelected);
                    updateWishlist(data.product.isInWishlist, data.product.id, variantSelected);
                    updatePDPUrl(data, variantSelected);
                    updateTrackingInfoTemplate(data, variantSelected);
                },
                error: function () {
                    $.spinner().stop();
                }
            });
        });
    },

    selectedVariantQuickView: function () {
        $('body').on('click', '.js-variant-select', function () {
            var btnSelectVariant = $(this);
            $('.js-variant-select').not(this).removeClass('selectedQuickView');
            btnSelectVariant.addClass('selectedQuickView');
            var colorVariant = btnSelectVariant.data('attr-value');
            var modal = btnSelectVariant.closest('#quickViewModal');
            var pid = modal.data('pid');
            var cardSelector = $('.product-' + pid + '.hover-active');
            var selectorVariant = cardSelector.find('.js-btn-variant-product-tile');
            var urlSelect = btnSelectVariant.data('url');

            $.ajax({
                url: urlSelect,
                type: 'GET',
                dataType: 'json',
                data:'json',
                success: function (data) {
                    replacePrice(data.product.price, selectorVariant);
                    replaceImg(data.product.images.medium, selectorVariant, data.product.id);
                    replaceTileBtnData(data, selectorVariant);
                    updateWishlist(data.product.isInWishlist, data.product.id, selectorVariant);
                    updatePDPUrl(data, selectorVariant);
                    updateTrackingInfoTemplate(data, selectorVariant);

                    cardSelector.find('.js-btn-variant-product-tile').each(function() {
                        $(this).removeClass('selected');

                        var classes = $(this).attr('class').split(/\s+/);
                        $.each(classes, function(index, className) {
                            if (className.startsWith('order-')) {
                                $(this).removeClass(className);
                            }
                        }.bind(this));

                        $(this).addClass('order-1');
                    });

                    let btnVariantChange = cardSelector.find('[data-variant-color-id=' + colorVariant + ']');
                    btnVariantChange.addClass('selected');

                    if (btnVariantChange.hasClass('d-none')) {
                        btnVariantChange.removeClass('d-none');
                        btnVariantChange.removeClass('order-1');
                        btnVariantChange.addClass('order-0');

                        let visibleElements = cardSelector.find('.js-btn-variant-product-tile:not(.d-none).order-1');

                        if (visibleElements.length > 0) {
                            visibleElements.last().addClass('d-none');
                        }
                    }

                    let btnAddCartQuickView = btnSelectVariant.closest('.modal').find('.js-btn-quickView-add-cart');
                    btnAddCartQuickView.data('pid', data.product.id);
                    btnAddCartQuickView.attr('disabled', !data.product.available);
                },
                error: function () {
                    $.spinner().stop();
                }
            });
        });
    },

    showPDPClickIframeVideo: function () {
        $('.js-iframe-overlay').on('click', function () {
            if (!$(this).hasClass('js-iframe-overlay-pdp')) {
                let element = $(this);
                window.location.href = element.attr('href');
            }
        });
    },
};
