'use strict';

const cache = {
    $body: $('body'),
    $carousels: $('.carousel:not(.carousel-responsive)')
};

const Utils = {
    Responsive: {
        _sm: 768,

        _md: 960,

        _l: 1024,

        _lg: 1366
    },

    Events: {
        Init: "carousel:initialized",
        SingleInit: "carousel:initialized:single",
        BeforeSlide: "carousel:beforeslide",
        Destroyed: "carousel:destroyed",
        MovedTo: "carousel:movedTo",
        Swiped: "carousel:swiped",
        AfterSlide: "carousel:afterSlide",
        Create: "carousel:create",
        Destroy: "carousel:destroy"
    }
};

var oldWidth;

function getFullSelector() {
    return [
        '.carousel:not(.carousel-responsive)',
        getSelectorByResolution()
    ].join(', ');
}

function bindCustomEvents() {
    cache.$body.on(Utils.Events.Create, function(e, el) {
        bindSingleCarousel(el);
    });

    cache.$body.on(Utils.Events.Destroy, function(e, el) {
        destroySingleCarousel(el);
    });

    cache.$body.on('modal:afterOpen', function(e, data) {
        var carousels = $(data).find(getFullSelector());
        if (carousels.length !== 0) {
            $.each(carousels, function(i, car) {
                bindSingleCarousel(car);
            });
        }
    });
}

function destroySingleCarousel(element) {
    var self = $(element);
    if (self.is(".slick-initialized")) {
        self.slick("unslick");

        // Remove element, just to be sure
        self
            .removeClass("slick-vertical")
            .find(".slick-list")
            .remove();

        self.trigger(Utils.Events.Destroyed);
    }
}

function bindEvents() {
    $(window).resize(function () {
        let sel = getSelectorByResolution(oldWidth);
        sel = sel + ":not(" + getSelectorByResolution() + ")";

        $(sel).each(function(i, el) {
            destroySingleCarousel(this);
        });

        sel = getSelectorByResolution();

        $(sel).each(function(i, el) {
            bindSingleCarousel(this);
        });

        if ($(window).width() !== oldWidth) {
            oldWidth = $(window).width();
        }
    });
}

function setResponsiveSetting(opts, res, option, value) {
    if (typeof opts.responsive == "undefined") {
        opts.responsive = [];
    }

    var resPresent = false;
    $.each(opts.responsive, function(i, resolution) {
        if (resolution["breakpoint"] === res) {
            resPresent = true;
        }
    });

    if (!resPresent) {
        opts.responsive.push({
            breakpoint: res,
            settings: {}
        });
    }

    $.each(opts.responsive, function(i, resolution) {
        if (resolution["breakpoint"] === res) {
            resolution["settings"][option] = value;
        }
    });

    return opts;
}

function getOpts($element) {
    var data = $element.data();
    var customPaging;
    var context = data["context"];
    var scope = context ? $element.closest(context) : $element;

    if (data["thumbs"]) {
        var thumbs = data["thumbs"].split("|");
        customPaging = function(slick, index) {
            return '<img src="' + thumbs[index] + '" alt=""/>';
        };
    }

    if (data["paged"]) {
        $element.addClass("paged");
        customPaging = function(slick, i) {
            var txt = i + 1 + "/" + slick.$slides.length;
            return $('<span class="page"/>').text(txt);
        };
    }

    if (data["prevArrow"]) {
        data["prevArrow"] = scope.find(data["prevArrow"]);
    }

    if (data["nextArrow"]) {
        data["nextArrow"] = scope.find(data["nextArrow"]);
    }

    if (data["asNavFor"]) {
        data["asNavFor"] = scope.find(data["asNavFor"]);
    }

    var opts = {
        accessibility: typeof data["accessibility"] != "undefined" ? data["accessibility"] : false, // Enables tabbing and arrow key navigation
        adaptiveHeight: typeof data["adaptiveHeight"] != "undefined" ? data["adaptiveHeight"] : false, // Enables adaptive height for single slide horizontal carousels.
        asNavFor: data["asNavFor"] || null, // Set the slider to be the navigation of other slider (Class or ID Name)
        autoplay: typeof data["autoplay"] != "undefined" ? data["autoplay"] : false, // Enables Autoplay
        autoplaySpeed: data["autoplaySpeed"] || 3000, // Autoplay Speed in milliseconds
        appendDots: data["appendDots"],
        appendArrows: data["appendArrows"] || $element,
        arrows: typeof data["arrows"] != "undefined" ? data["arrows"] : true, // Prev/Next Arrows
        prevArrow: data["prevArrow"] || '<div class="slick-prev" aria-label="Previous"><i></i></div>',
        nextArrow: data["nextArrow"] || '<div class="slick-next" aria-label="Next"><i></i></div>',
        centerMode: typeof data["centerMode"] != "undefined" ? data["centerMode"] : false, // Enables centered view with partial prev/next slides. Use with odd numbered slidesToShow counts.
        centerPadding: data["centerPadding"] || "50px", // Side padding when in center mode (px or %)
        cssEase: data["cssEase"] || "ease", // CSS3 Animation Easing
        customPaging: customPaging || data["customPaging"],
        dots: typeof data["dots"] != "undefined" ? data["dots"] : customPaging != null, // Show dot indicators
        dotsClass: data["dotsClass"] || "slick-dots",
        draggable: typeof data["draggable"] != "undefined" ? data["draggable"] : true, // Enable mouse dragging
        easing: data["easing"] || "linear", // Add easing for jQuery animate. Use with easing libraries or default easing methods
        edgeFriction: data["edgeFriction"] || 0.35, // Resistance when swiping edges of non-infinite carousels
        fade: typeof data["fade"] != "undefined" ? data["fade"] : false, // Enable fade
        focusOnSelect: typeof data["focusOnSelect"] != "undefined" ? data["focusOnSelect"] : false, // Enable focus on selected element (click)
        infinite: typeof data["infinite"] != "undefined" ? data["infinite"] : true, // Infinite loop sliding
        initialSlide: data["initialSlide"] || 0, // Slide to start on
        lazyLoad: data["lazyLoad"] || "ondemand", // Set lazy loading technique. Accepts 'ondemand' or 'progressive'
        mobileFirst: typeof data["mobileFirst"] != "undefined" ? data["mobileFirst"] : false, // Responsive settings use mobile first calculation
        pauseOnHover: typeof data["pauseOnHover"] != "undefined" ? data["pauseOnHover"] : true, // Pause Autoplay On Hover
        pauseOnDotsHover: typeof data["pauseOnDotsHover"] != "undefined" ? data["pauseOnDotsHover"] : false, // Pause Autoplay when a dot is hovered
        respondTo: data["respondTo"] || "window", // Width that responsive object responds to. Can be 'window', 'slider' or 'min' (the smaller of the two)
        rows: data["rows"] || 1, // Setting this to more than 1 initializes grid mode. Use slidesPerRow to set how many slides should be in each row.
        slide: data["slide"] || "", // Element query to use as slide
        slidesPerRow: data["slidesPerRow"] || 1, // With grid mode intialized via the rows option, this sets how many slides are in each grid row. dver
        slidesToShow: data["slidesToShow"] || 3, // # of slides to show
        slidesToScroll: data["slidesToScroll"] || 1, // # of slides to scroll
        speed: data["speed"] || 500, // Slide/Fade animation speed
        swipe: typeof data["swipe"] != "undefined" ? data["swipe"] : true, // Enable swiping
        swipeToSlide: typeof data["swipeToSlide"] != "undefined" ? data["swipeToSlide"] : false, // Allow users to drag or swipe directly to a slide irrespective of slidesToScroll
        touchMove: typeof data["touchMove"] != "undefined" ? data["touchMove"] : true, // Enable slide motion with touch
        touchThreshold: data["touchThreshold"] || 5, // To advance slides, the user must swipe a length of (1/touchThreshold) * the width of the slider
        useCSS: typeof data["useCSS"] != "undefined" ? data["useCSS"] : true, // Enable/Disable CSS Transitions
        variableWidth: typeof data["variableWidth"] != "undefined" ? data["variableWidth"] : false, // Variable width slides
        vertical: typeof data["vertical"] != "undefined" ? data["vertical"] : false, // Vertical slide mode
        verticalSwiping: typeof data["verticalSwiping"] != "undefined" ? data["verticalSwiping"] : false, // Vertical swipe mode
        waitForAnimate: typeof data["waitForAnimate"] != "undefined" ? data["waitForAnimate"] : true,
        zIndex: data["zIndex"] || 10,
        slideTitleSelector: data["slideTitleSelector"],
        titleElement: scope.find(data["titleSelector"]),
        titleType: data["titleType"]
    };

    if (data["slidesToShowSm"]) {
        setResponsiveSetting(opts, Utils.Responsive._md, "slidesToShow", data["slidesToShowSm"]);
        setResponsiveSetting(
            opts,
            Utils.Responsive._md,
            "slidesToScroll",
            data["slidesToScrollSm"]
        );
    }

    if (data["slidesToShowS"]) {
        setResponsiveSetting(opts, 900, "slidesToShow", data["slidesToShowS"]);
        setResponsiveSetting(opts, 900, "slidesToScroll", data["slidesToShowS"]);
    }

    if (data["slidesToShowXs"]) {
        setResponsiveSetting(opts, 500, "slidesToShow", data["slidesToShowXs"]);
        setResponsiveSetting(opts, 500, "slidesToScroll", data["slidesToScrollXs"]);
    }

    if (data["slidesToShowL"]) {
        setResponsiveSetting(opts, 1060, "slidesToShow", data["slidesToShowL"]);
        setResponsiveSetting(opts, 1060, "slidesToScroll", data["slidesToScrollL"]);
    }

    if (typeof data["appendDotsXs"] != "undefined") {
        setResponsiveSetting(opts, Utils.Responsive._sm, "appendDots", data["appendDotsXs"]);
    }

    if (typeof data["verticalXs"] != "undefined") {
        setResponsiveSetting(opts, Utils.Responsive._sm, "vertical", data["verticalXs"]);
    }

    if (typeof data["verticalSwipingXs"] != "undefined") {
        setResponsiveSetting(
            opts,
            Utils.Responsive._sm,
            "verticalSwiping",
            data["verticalSwipingXs"]
        );
    }

    if (typeof data["dotsXs"] != "undefined") {
        setResponsiveSetting(opts, Utils.Responsive._sm, "dots", data["dotsXs"]);
    }

    if (typeof data["dotsSm"] != "undefined") {
        setResponsiveSetting(opts, Utils.Responsive._md, "dots", data["dotsSm"]);
    }

    if (typeof data["arrowsXs"] != "undefined") {
        setResponsiveSetting(opts, Utils.Responsive._sm, "arrows", data["arrowsXs"]);
    }

    if (typeof data["fadeXs"] != "undefined") {
        setResponsiveSetting(opts, Utils.Responsive._sm, "fade", data["fadeXs"]);
    }

    if (typeof data["asNavForXs"] != "undefined") {
        setResponsiveSetting(opts, Utils.Responsive._sm, "asNavFor", data["asNavForXs"]);
    }

    if (typeof data["centerModeXs"] != "undefined") {
        setResponsiveSetting(opts, Utils.Responsive._sm, "centerMode", data["centerModeXs"]);
    }

    if (typeof data["centerPaddingXs"] != "undefined") {
        setResponsiveSetting(opts, Utils.Responsive._sm, "centerPadding", data["centerPaddingXs"]);
    }

    return opts;
}

function bindSingleCarousel(element) {
    var self = $(element);

    if (self.length <= 0) {
        return;
    }

    if (self.is(".disabled")) {
        return self;
    }

    if (self.is(".slick-initialized")) {
        return self;
    }

    var opts = getOpts(self);

    self
        .off("init")
        .on("init", function(e, slick) {
            e.type = Utils.Events.Init;
            $(this).trigger(Utils.Events.SingleInit, [self, slick]);
            // Accessiblity issues with Slick.js are preventing us from make it available for WCAG
            self.attr("aria-hidden", true);
            self.trigger(e);
        })
        .on("beforeChange", function(e, slick, currentSlide, nextSlide) {
            slick.$slides.removeClass("out-to-left out-to-right");
            e.type = Utils.Events.BeforeSlide;
            self.trigger(e, [slick, currentSlide, nextSlide]);
            var max = slick.$slides.length - 1;
            var current = $(slick.$slides[currentSlide]);
            var next = $(slick.$slides[nextSlide]);
            if (
                (currentSlide < nextSlide && !(currentSlide == 0 && nextSlide == max)) ||
                (nextSlide == 0 && currentSlide == max)
            ) {
                current.addClass("out-to-left");
                next.addClass("in-from-right");
            } else {
                current.addClass("out-to-right");
                next.addClass("in-from-left");
            }
        })
        .on("destroy", function(e, slick) {
            e.type = Utils.Events.Destroyed;
            self.trigger(e, slick);
        })
        .on("setPosition", function(e, slick) {
            e.type = Utils.Events.MovedTo;
            self.trigger(e, slick);
        })
        .on("swipe", function(e, slick, direction) {
            e.type = Utils.Events.Swiped;
            self.trigger(e, [slick, direction]);
        })
        .on("afterChange", function(e, slick, index) {
            var currentSlide = $(slick.$slides[index]);
            slick.$slides.not(currentSlide).removeClass("in-from-left in-from-right");

            if (opts["slideTitleSelector"]) {
                var slideTitleElem = currentSlide.find(opts["slideTitleSelector"]);

                if (opts["titleElement"]) {
                    if (opts["titleType"] === "html") {
                        opts["titleElement"].html(slideTitleElem.html());
                    } else {
                        opts["titleElement"].text(slideTitleElem.text());
                    }
                }
            }
            e.type = Utils.Events.AfterSlide;
            self.trigger(e, [slick, index, currentSlide]);
        })
        .slick(opts)
        .on("setPosition", function(event, slick) {
            slick.$slides.css("height", slick.$slideTrack.height() + "px");
        });
}

function getCurrentResolution(widthRes) {
    let width = widthRes || $(window).width();

    if (width < Utils.Responsive._sm) {
        return 'xs';
    } else if (width < Utils.Responsive._md) {
        return 'sm';
    } else if (width < Utils.Responsive._l) {
        return 'md';
    } else if (width < Utils.Responsive._lg) {
        return 'l';
    } else {
        return 'lg';
    }
}

function getSelectorByResolution(widthRes) {
    let res = getCurrentResolution(widthRes);

    return '.carousel-responsive.carousel-' + res;
}

function buildCarousels(sel) {
    let carousels = sel ? $(sel) : cache.$carousels;

    carousels.each(function (i, element) {
        bindSingleCarousel(element);
    });
}

function initCarousels() {
    buildCarousels();

    let selRes = getSelectorByResolution();
    buildCarousels(selRes);
}

$(document).ready(function () {
    oldWidth = $(window).width();

    initCarousels();
    bindEvents();
    bindCustomEvents();
    cache.$body.trigger(Utils.Events.Init);
});
