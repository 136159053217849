'use strict';

require('waypoints/lib/jquery.waypoints');
var processInclude = require('base/util');
var formValidation = require('./components/formValidation');
const eventHandlers = require('./tracking/eventHandlers');
const eventsType = eventHandlers.eventsType;

$(document).ready(function () {
    processInclude(require('base/productDetail'));
    processInclude(require('./product/detail'));
    processInclude(require('./components/01-components/shareSocial'));
});

const cache = {
    $body: $('body'),
    $selectorSwiper: '.js-swiper-product-detail',
    $selectorSwiperLastVisited: '.js-swiper-last-visited',
    $productImagesSwiper: $('.js-swiper-product-detail'),
    $navItemLinkTabDescription: $('.js-nav-item'),
    $navTabDescription: $('.js-tab-description'),
    $headerMenu: $('.js-header'),
    $headerPDP: $('.js-header-pdp'),
    $btnAddQuantity: $('.js-btn-add-quantity'),
    $btnRemoveQuantity: $('.js-btn-remove-quantity'),
    $quantityValue: $('.js-quantity-value'),
    $btnAddToCart: $('.js-add-to-cart-pdp'),
    $btnAttributeColor: $('.js-btn-color-attribute'),
    $selectAttributeColor: $('.js-variant-select'),
    $btnOpenCollapse: $('.js-btn-open-collapse'),
    $collapseSelected: $('.js-btn-text'),
    $collapseImage: $('.js-collapse-img'),
    $collapseVariant: $('.js-collapse-variants'),
    $productImageCol: $('.js-col-product-image'),
    $slideVimeoPreview: $('.js-slide-vimeo-preview'),
    $slideVimeo: $('.js-slide-vimeo'),
    $btnWishListPDP: $('.js-wishlist-pdp'),
    $productSKU:  $('.js-product-sku'),
    $productImageHeaderPDP: $('.js-product-image-header-pdp'),
    $colAddCart: $('.js-col-add-cart'),
    $colQuantity: $('.js-col-quantity'),
    $productDetail: $('.js-tracking-detail'),

    //LOGIC PANEL
    $panelDescriptionMobile: $('.js-panel-description-mobile'),
    $panelDescriptionMobileTab: $('.js-tab-description-mobile'),
    $panelDescriptionMobileTabPane: $('.js-tab-description-mobile .tab-pane'),
    $panelVariantMobile: $('.js-panel-variant-mobile'),
    $priceProduct: $('.prices'),
    $priceProductList: $('.js-price-list'),
    $pricePercentagePDP: $('.js-percentage-pdp'),
    $pricePercentageHeaderPDP: $('.js-header-pdp .discount-percentage'),
    $priorBestPrice: $('.js-prior-best-price'),
    $swiperWrapperProductDetail: $('.js-wrapper-product-detail'),

    //LOGIC NOTIFYME
    $formNotifyMe: $('.js-form-notifyme'),
    $modalNotifyMe: $('.js-modal-notifyme'),
    $modalContentNotifyMeForm: $('.js-modal-content-notifyme-form'),
    $modalContentNotifyMeSuccess: $('.js-modal-content-notifyme-succes'),
    $modalContentNotifyMeError: $('.js-modal-content-notifyme-error'),
    $btnShowModalNotifyMe: $('.js-btn-notifyme'),
    inputEmailNotifyMe: $('.js-input-form'),
    checkboxPrivacyNotifyMe: $('.js-checkbox-form'),

    // SHARE BUTTON
    $shareDropdownButton: $('.js-shareDropdown'),

    //LOGIC FILTER PDP
    $btnFilterColor: $('.js-btn-filter-pdp'),

    $placeholderSmall: $('input[name="noimage_placeholder_small"]'),
    $placeholderMedium: $('input[name="noimage_placeholder_medium"]'),
    $placeholderLarge: $('input[name="noimage_placeholder_large"]')
};

// GLOBAL VARIANTS
let productImagesSwiper = null;
let heightPositionYPDP;
var statusOpenCollapseMobile = false;

function changeContentModal(data, status) {

    switch (status) {
        case "success":
            cache.$modalContentNotifyMeSuccess.removeClass('hide');
            cache.$modalContentNotifyMeForm.addClass('hide');
            cache.$modalContentNotifyMeError.addClass('hide');
            break;
        case "error" :
            cache.$modalContentNotifyMeError.removeClass('hide');
            cache.$modalContentNotifyMeSuccess.addClass('hide');
            cache.$modalContentNotifyMeForm.addClass('hide');
            $('.js-message-error-notifyme').text(data.Error);
            break;
        default:
            cache.$modalContentNotifyMeForm.removeClass('hide');
            cache.$modalContentNotifyMeSuccess.addClass('hide');
            cache.$modalContentNotifyMeError.addClass('hide');
            cache.inputEmailNotifyMe.val('');
            cache.checkboxPrivacyNotifyMe.prop('checked', false);
    }
}

function closeCollapseVariantMobile(buttonCollapse) {
    buttonCollapse.find('.js-collapse-variants').removeClass('show');
    cache.$panelVariantMobile.addClass('hide');
    $(window).scrollTop(heightPositionYPDP);
    cache.$body.removeClass('modal-open');
    statusOpenCollapseMobile = false;
}

function updatePrice(data) {
    cache.$priceProduct.find('.sales').find('.value').html(data.product.price.sales.formatted);
    cache.$priceProduct.find('.sales').find('.value').attr('content', data.product.price.sales.value);

    if (data.product.price.list) {
        cache.$priceProduct.find('.strike-through').find('.value').attr('content', data.product.price.list.value);
        let labelERP = cache.$priceProductList.data('label');
        cache.$priceProductList.html(labelERP + ' ' + data.product.price.list.formatted);

        if (data.product.discountPercentage) {
            cache.$pricePercentagePDP.html("(-" + data.product.discountPercentage + "%)");
            cache.$pricePercentageHeaderPDP.html("-" + data.product.discountPercentage + "%");
        }
    } else {
        cache.$priceProductList.empty();
        cache.$pricePercentagePDP.empty();
        cache.$pricePercentageHeaderPDP.empty();
    }

    if (data.product.priorBestPrice) {
        let labelPriorBestPrice = cache.$priorBestPrice.data('label');
        cache.$priorBestPrice.html(labelPriorBestPrice + ' ' + data.product.priorBestPrice.list.formatted);
    } else {
        cache.$priorBestPrice.empty();
    }
}

function updateImageCarousel(data) {
    var $swiperWrapper = cache.$swiperWrapperProductDetail;
    var numImages = data.product.images.zoom.length;
    var $newSlide;
    $swiperWrapper.find('.js-swiper-slide-product-detail').remove();

    if(!data.product.vimeoPreviewID) {
        $('.js-slide-vimeo-preview').remove();
    } else {
        var $vimeoPreviewSlide = $('<div class="swiper-slide js-slide-vimeo-preview"></div>');

        var $vimeoIframe = $('<iframe class="vimeo-iframe" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen ' +
            'style="position:absolute;top:0;left:0;width:100%;height:100%;"></iframe>');

        var vimeoPreviewID = data.product.vimeoPreviewID;
        $vimeoIframe.attr('src', 'https://player.vimeo.com/video/' + vimeoPreviewID + '?controls=0&color=e60000&autoplay=1&loop=1&muted=1&title=0&byline=0&portrait=0&dnt=1');

        var $iframeOverlay = $('<div class="iframe-overlay js-iframe-overlay js-iframe-overlay-pdp" data-toggle="modal" data-target="#zoomImagePDP" ' +
            'style="position: absolute; top: 0; left: 0; width: 100%; height: 100%; cursor: pointer;"></div>');

        $vimeoPreviewSlide.append($vimeoIframe, $iframeOverlay);

        $swiperWrapper.before($vimeoPreviewSlide);
    }

    for (var i = 0; i < numImages; i++) {
        if (i === 0) {
            $newSlide = $('<div class="swiper-slide js-swiper-slide-product-detail">' +
                '<picture>' +
                '<source srcset="" media="(max-width: 769px)" />' +
                '<img class="image-product js-image-swiper" data-toggle="modal" data-target="#zoomImagePDP">' +
                '</picture>' +
                '</div>');
        } else {
            $newSlide = $('<div class="swiper-slide js-swiper-slide-product-detail">' +
                '<picture>' +
                '<source srcset="" media="(max-width: 769px)" />' +
                '<img class="image-product js-image-swiper hide not-first-image" data-toggle="modal" data-target="#zoomImagePDP">' +
                '</picture>' +
                '</div>');
        }

        if ($('.js-slide-vimeo-preview').length > 0) {
            cache.$slideVimeoPreview.after($newSlide);
        } else {
            if ($('.js-slide-vimeo').length > 0) {
                cache.$slideVimeo.before($newSlide);
            } else {
                $swiperWrapper.append($newSlide);
            }
        }
    }

    $('.js-image-swiper').each(function(index) {
        $(this).attr('src', data.product.images['large_pdp'][index].url);
        $(this).attr('alt', data.product.images['large_pdp'][index].alt);

        let picture = $(this).closest('picture');
        picture.find('source').attr('srcset', data.product.images['medium_pdp'][index].url);
    });

    if (numImages === 0) {
        $newSlide = $('<div class="swiper-slide js-swiper-slide-product-detail">' +
            '<picture><img class="image-product js-image-swiper" src="' + cache.$placeholderLarge[0].value + '">' +
            '</picture></div>');

        $swiperWrapper.append($newSlide);
    }

    if ($('.js-image-swiper')[0]) {
        $('.js-image-swiper')[0].style.width = '100%';
        $('.js-image-swiper')[0].style.height = 'auto';
    }

    initSwiperProductDetail();
    initSmartScroll();
}

function updateWishlistPDP(isInWishlist, id) {
    if (!isInWishlist) {
        cache.$btnWishListPDP.data('pid', id);
        cache.$btnWishListPDP.addClass('icon-heart js-add-to-wishlist').removeClass('icon-heart-full js-remove-from-wishlist');
        let oldUrl = cache.$btnWishListPDP.data('remove-from-wishlist');
        let newUrl = oldUrl.replace(/pid=[^&]*/, "pid=" + id);
        cache.$btnWishListPDP.data('remove-from-wishlist', newUrl);
    } else {
        cache.$btnWishListPDP.data('pid', id);
        cache.$btnWishListPDP.addClass('icon-heart-full js-remove-from-wishlist').removeClass('icon-heart js-add-to-wishlist');
        let oldUrl = cache.$btnWishListPDP.data('remove-from-wishlist');
        let newUrl = oldUrl.replace(/pid=[^&]*/, "pid=" + id);
        cache.$btnWishListPDP.data('remove-from-wishlist', newUrl);
    }
}

function updateAvailability(product, available, id, notifyMeDisabled) {
    if(available) {
        // WE SHOW THE QUANTITY AND ADD TO THE CART
        cache.$colAddCart.removeClass('d-none');
        cache.$colQuantity.removeClass('d-none');
        cache.$btnAddToCart.removeClass('d-none');

        // WE UPDATE TEST QUANTITY BOX
        cache.$quantityValue.text(product.minOrderQuantity);
        cache.$quantityValue.data('value-quantity', product.minOrderQuantity);
        cache.$quantityValue.data('max', product.maxOrderQuantity);
        cache.$quantityValue.data('min', product.minOrderQuantity);
        cache.$quantityValue.data('max-quantity', product.quantityOptions.maxOrderQuantity);
        cache.$btnRemoveQuantity.addClass('disabled');
        cache.$btnAddQuantity.removeClass('disabled');

        // WE HIDE THE NOTIFYME
        cache.$btnShowModalNotifyMe.addClass('d-none');
    } else {
        var labelAvailable = cache.$btnShowModalNotifyMe.data('available-label');
        var labelNotifyMeDisabled = cache.$btnShowModalNotifyMe.data('notifyme-disabled-label');

        // LOGICAL MANAGEMENT OF THE NOTIFYMEDISABLED ATTRIBUTE
        if (notifyMeDisabled) {
            cache.$btnShowModalNotifyMe.text(labelNotifyMeDisabled).prop('disabled', true);
        } else {
            cache.$btnShowModalNotifyMe.html(labelAvailable).prop('disabled', false);
        }

        // WE HIDE THE QUANTITY AND TWO BUTTONS OF THE ADD TO THE CART
        cache.$colAddCart.addClass('d-none');
        cache.$colQuantity.addClass('d-none');
        cache.$btnAddToCart.addClass('d-none');

        // LET'S SHOW THE NOTIFYME
        cache.$btnShowModalNotifyMe.removeClass('d-none');
        $('.js-pid-notifyme').attr('value', id);
    }
}

function updateHeaderPDP(product) {
    let $newImage;
    cache.$productImageHeaderPDP.find('.image-product-header').remove();

    if (product.images && product.images.small && product.images.small[0]) {
        $newImage = $('<img class="image-product-header mr-3" src="' + product.images.small[0].url + '"' +
            'title="' + product.images.small[0].title + '" alt="' + product.images.small[0].alt + '">');
    } else {
        $newImage = $('<img class="image-product-header mr-3" src="' + cache.$placeholderSmall[0].value + '"' +
            'title="PUPA Milano" alt="PUPA Milano">');
    }

    cache.$productImageHeaderPDP.append($newImage);
}

function updateSocialShareLinks(productUrl, title, image, alreadyHasParams) {
    const url = encodeURIComponent(productUrl);
    const prefix = alreadyHasParams ? '%26utm_source=' : '%3Futm_source=';
    const suffix = '%26utm_medium=post-organico%26utm_campaign=shareWebsite';

    const data = cache.$shareDropdownButton.data();
    const mailUrl = data.mail + data.name + url;

    const shareData = [
        { platform: 'copyLink', url: decodeURIComponent(url), addParams: false },
        { platform: 'facebook', url: `https://www.facebook.com/sharer/sharer.php?u=${url}`, addParams: true },
        { platform: 'twitter', url: `http://twitter.com/share?text=${title}&url=https://${url}&image-src=${image}`, addParams: true },
        { platform: 'pinterest', url: `http://pinterest.com/pin/create/button/?url=${url}&media=${image}&description=${title}`, addParams: true },
        { platform: 'mail', url: mailUrl, addParams: true }
    ];

    shareData.forEach(({ platform, url, addParams }) => {
        const target = `.js-${platform}`;
        let href = url;

        if (addParams) {
            const params = `${prefix}${platform}${suffix}`;
            href += params;
        }

        $(target).attr('href', href);
    });
}

function initSwiperProductDetail() {
    if (!productImagesSwiper) {
        productImagesSwiper = new Swiper(cache.$selectorSwiper, {
            modules: [Autoplay, Navigation, Pagination, Mousewheel, Parallax],
            slidesPerView: 1,
            spaceBetween: 0,
            parallax: true,
            noSwiping: true,
            allowSlidePrev: true,
            allowSlideNext: true,
            effect: 'fade',
            watchSlidesProgress: true,
            pagination: {
                el: '.swiper-pagination',
                type: 'fraction'
            },
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
            mousewheel: {
                eventsTarget: 'body',
                enabled: false,
                thresholdTime: 350,
                sensitivity: 0.1,
                thresholdDelta: 50
            },
            breakpoints: {
                768: {
                    noSwiping: false,
                    allowSlidePrev: false,
                    allowSlideNext: false
                },
            },
            on: {
                slideChange: function(swiper) {
                    var activeSlide = swiper.slides[swiper.activeIndex];
                    swiper.slides.removeClass('swiper-slide-active');
                    $(activeSlide).addClass('swiper-slide-active');
                    $('.js-image-swiper').addClass('hide');
                    var $imgInActiveSlide = $(activeSlide).find('img');
                    $imgInActiveSlide.removeClass('hide');

                    if (isSmartScrollEnabled) {
                        isSwiperBlockingScroll = true;
                    }
                },
                reachBeginning: function() {
                    setTimeout(function() {
                        isSwiperBlockingScroll = false;
                    }, 500);
                },
                reachEnd: function () {
                    setTimeout(function() {
                        isSwiperBlockingScroll = false;
                    }, 500);
                }
            },
        });
    } else {
        productImagesSwiper.update();
    }

    if (productImagesSwiper.slides.length > 0) {
        productImagesSwiper.slideTo(0, 0);
    }
}

function initSwiperLastVisited() {
    const lastVisitedSwiper = new Swiper(cache.$selectorSwiperLastVisited, {
        slidesPerView: 1.5,
        spaceBetween: 28,
        effect: 'fade',
    });
    var carouselContainer = $(cache.$selectorSwiperLastVisited);

    var infoItems = carouselContainer.find('.js-info-tracking');
    if(infoItems.length > 0) {
        eventHandlers.sendEvent(infoItems, eventsType.viewItemList);
    }
}

let swiperWaypoints = [];

let isSwiperBlockingScroll = false;
let isBodyScrollBlocked = false;
let isSmartScrollEnabled = false;

function togglePaginationAndNavigation(show) {
    let display = show ? 'block' : 'none';
    $('.js-swiper-arrow').css('display', display);
    $('.js-swiper-pagination-pdp').css('display', display);
}

function initSwiperScrollWaypoints() {
    let upToDownSwiperWaypoint = new Waypoint({
        element: cache.$productImageCol,
        handler: function(direction) {
            if (direction === 'down' && !isBodyScrollBlocked) {
                togglePaginationAndNavigation(true);
                toggleBlockBodyScroll(true, this);
            }
        },
        offset: '-50%'
    });

    let onBottomSwiperWaypoint = new Waypoint({
        element: cache.$productImageCol,
        handler: function(direction) {
            if (!isBodyScrollBlocked && ((direction === 'up' && !productImagesSwiper.isBeginning) || (direction === 'down' && !productImagesSwiper.isEnd))) {
                toggleBlockBodyScroll(true, this);
                togglePaginationAndNavigation(true);
            }
        },
        offset: 'bottom-in-view'
    });

    let downToUpSwiperWaypoint = new Waypoint({
        element: cache.$productImageCol,
        handler: function(direction) {
            if (direction === 'up' && !isBodyScrollBlocked) {
                togglePaginationAndNavigation(true);
                toggleBlockBodyScroll(true, this);
            }
        },
        offset: '-110%'
    });

    swiperWaypoints.push(upToDownSwiperWaypoint);
    swiperWaypoints.push(onBottomSwiperWaypoint);
    swiperWaypoints.push(downToUpSwiperWaypoint);
}

function toggleBlockBodyScroll(enabled, waypointInstance) {
    if (enabled === undefined) {
        toggleBlockBodyScroll(!isBodyScrollBlocked, waypointInstance);
    }

    document.body.style.overflow = enabled ? 'hidden' : 'auto';
    isBodyScrollBlocked = enabled;

    if (waypointInstance) {
        let elementBottomPos = waypointInstance.element[0].getBoundingClientRect().bottom;
        waypointInstance.disable();
        scrollToPosition(window.scrollY + elementBottomPos - window.innerHeight, null, function() {
            productImagesSwiper.allowSlidePrev = true;
            productImagesSwiper.allowSlideNext = true;
            productImagesSwiper.params.noSwiping = true;
            productImagesSwiper.mousewheel.enable();
            waypointInstance.enable();
        });
    }
}

function scrollToPosition(targetPosition, speed, callback) {
    $('html, body').animate({
        scrollTop: targetPosition
    }, speed || 250, 'swing', callback);
}

function initSmartScroll() {
    if (!productImagesSwiper || productImagesSwiper.length > 1 || productImagesSwiper.slides.length <= 1 || window.isMobile()) {
        isSmartScrollEnabled = false;
        return;
    }

    $(window).off('wheel').on('wheel', function (e) {
        if (!isSmartScrollEnabled) {
            return true;
        }
        if (isBodyScrollBlocked) {
            // Scroll unlock
            let deltaY = e.originalEvent.deltaY;
            if (!isSwiperBlockingScroll && ((deltaY < 0 && productImagesSwiper.isBeginning) || (deltaY > 0 && productImagesSwiper.isEnd))) {
                toggleBlockBodyScroll(false);
                if (!productImagesSwiper.isEnd) {
                    togglePaginationAndNavigation(false);
                }
                productImagesSwiper.allowSlidePrev = false;
                productImagesSwiper.allowSlideNext = false;
                productImagesSwiper.params.noSwiping = false;
                productImagesSwiper.mousewheel.disable();
            }
        }
    });

    swiperWaypoints.forEach(wp => wp.destroy());

    initSwiperScrollWaypoints();

    isSmartScrollEnabled = true;
}

function disableSmartScroll() {
    swiperWaypoints.forEach(wp => wp.disable());
    isSmartScrollEnabled = false;
    isBodyScrollBlocked = false;
}

module.exports = function () {

    $(window).on('load', function () {
        initSwiperProductDetail();
        initSwiperLastVisited();
        initSmartScroll();

        if (window.isMobile()) {
            cache.$navItemLinkTabDescription.removeClass('active');
            cache.$navTabDescription.removeClass('show active');
            disableSmartScroll();
        }

        var firstViewportHeight = $(window).height();
        $(window).scroll(function () {
            var scrolled = $(this).scrollTop();
            if($('.page').data('action') === 'Product-Show') {
                if (scrolled >= firstViewportHeight) {
                    cache.$headerPDP.removeClass('hide');
                    if(!window.isMobile()) {
                        cache.$headerMenu.addClass('d-none');
                    } else {
                        $('.js-logo-mobile').addClass('d-none');
                    }
                } else {
                    cache.$headerPDP.addClass('hide');
                    cache.$headerMenu.removeClass('d-none');
                    $('.js-logo-mobile').removeClass('d-none');
                }
            }
        });

        if (cache.$navItemLinkTabDescription.length > 0) {
            cache.$navItemLinkTabDescription[0].classList.add('active');
        }

        if (cache.$navTabDescription.length > 0) {
            cache.$navTabDescription[0].classList.add('show');
            cache.$navTabDescription[0].classList.add('active');
        }
    });

    $(window).resize(function () {
        if (!window.isMobile()) {
            cache.$body.removeClass('modal-open');
            // close all modals
            cache.$navItemLinkTabDescription.each(function () {
                $(this.dataset.target + '-modal').modal('hide');
            });

            if (!isSmartScrollEnabled) {
                initSmartScroll();
            }
        } else {

            $('body').css('overflow', 'auto');

            if (isSmartScrollEnabled) {
                disableSmartScroll();
            }

            if (cache.$collapseVariant.hasClass('show')) {
                cache.$panelVariantMobile.removeClass('hide');
            } else {
                cache.$panelVariantMobile.addClass('hide');
            }
        }
    });

    cache.$btnAddQuantity.on('click', function () {
        var quantityBox = $(this).closest('.quantity-box');
        var quantityValueBox = quantityBox.find('.js-quantity-value');
        var valueQuantity = quantityValueBox.data('value-quantity');
        var maxQuantity = quantityValueBox.data('max-quantity');
        var max = quantityValueBox.data('max');
        cache.$btnRemoveQuantity.removeClass('disabled');

        var quantityTotal;

        if (maxQuantity > max) {
            quantityTotal = max;
        } else {
            quantityTotal = maxQuantity;
        }

        if(valueQuantity < quantityTotal ) {
            cache.$btnAddQuantity.removeClass('disabled');
            valueQuantity = valueQuantity + 1;
            quantityValueBox.data('value-quantity', valueQuantity);
            quantityValueBox.html(valueQuantity);
            if (valueQuantity === quantityTotal) {
                cache.$btnAddQuantity.addClass('disabled');
            }
        }
    });

    cache.$btnRemoveQuantity.on('click', function () {
        var valueQuantity = cache.$quantityValue.data('value-quantity');
        var min = cache.$quantityValue.data('min');
        cache.$btnAddQuantity.removeClass('disabled');
        if(valueQuantity > min ) {
            cache.$btnRemoveQuantity.removeClass('disabled');
            valueQuantity = valueQuantity - 1;
            cache.$quantityValue.data('value-quantity', valueQuantity);
            cache.$quantityValue.html(valueQuantity);
            if (valueQuantity === min) {
                cache.$btnRemoveQuantity.addClass('disabled');
            }
        }
    });

    cache.$btnAttributeColor.on('click', function () {
        let btnColorSelected = $(this);
        cache.$btnAttributeColor.not(this).removeClass('active-variant');
        $(this).addClass('active-variant');
        let textCollapse = $(this).data('attr-value') + " - " + $(this).data('attr-name');
        let imgCollapseUrl = $(this).data('attr-background-url');
        let urlVariantSelect = $(this).data('url');

        $.ajax({
            url: urlVariantSelect,
            type: 'GET',
            dataType: 'json',
            data:'json',
            success: function (data) {
                btnColorSelected.data('attr-variant-id', data.product.id);
                cache.$btnAddToCart.data('pid', data.product.id);
                updatePrice(data);
                updateImageCarousel(data);
                updateWishlistPDP(data.product.isInWishlist, data.product.id);
                updateHeaderPDP(data.product);
                updateAvailability(data.product, data.product.available, data.product.id, data.product.notifyMeDisabled);
                updateSocialShareLinks(
                    data.productUrl,
                    data.product.productName,
                    data.product.images.zoom && data.product.images.zoom[0]
                        ? data.product.images.zoom[0].url
                        : cache.$placeholderLarge[0].value,
                    true
                );
                cache.$productSKU.text(data.product.id);
                $(document).trigger('myCustomEvent', [data]);
                cache.$collapseSelected.html(textCollapse);
                cache.$collapseImage.attr('src', imgCollapseUrl);

                $('klarna-placement').attr('data-purchase-amount', data.formattedTotalGrossPrice);
                if (window.Klarna) {
                    window.Klarna.OnsiteMessaging.refresh();
                }
            },
            error: function () {
                $.spinner().stop();
            }
        });
    });

    cache.$selectAttributeColor.on('click', function () {
        let optionSelected = $(this);
        let valueSelect = $(this).data('attr-value');
        let urlVariantSelect = $(this).val();
        let textCollapse = $(this).data('attr-value') + ' - ' + $(this).data('attr-name');
        let imgCollapseUrl = $(this).data('attr-background-url');

        $.ajax({
            url: urlVariantSelect,
            type: 'GET',
            dataType: 'json',
            data:'json',
            success: function (data) {
                optionSelected.data('attr-variant-id', data.product.id);
                cache.$btnAddToCart.data('pid', data.product.id);
                updatePrice(data);
                updateImageCarousel(data);
                updateWishlistPDP(data.product.isInWishlist, data.product.id);
                updateHeaderPDP(data.product);
                updateAvailability(data.product, data.product.available, data.product.id, data.product.notifyMeDisabled);
                updateSocialShareLinks(
                    data.productUrl,
                    data.product.productName,
                    data.product.images.zoom && data.product.images.zoom[0]
                        ? data.product.images.zoom[0].url
                        : cache.$placeholderLarge[0].value,
                    true
                );
                cache.$productSKU.text(data.product.id);
                $(document).trigger('myCustomEvent', [data]);
                cache.$collapseSelected.html(textCollapse);
                cache.$collapseImage.attr('src', imgCollapseUrl);
                cache.$btnAttributeColor.filter(function () {
                    $(this).removeClass('active-variant');
                    if($(this).data('attr-value') === valueSelect) {
                        $(this).addClass('active-variant');
                    }
                });
                closeCollapseVariantMobile(optionSelected);
            },
            error: function () {
                $.spinner().stop();
            }
        });
        cache.$collapseVariant.collapse('hide');
    });

    cache.$navItemLinkTabDescription.on('click', function () {
        if (window.isMobile()) {
            let target = $(this)[0].dataset.target;
            let content = $(target)[0].innerHTML;

            let modal = $(target + '-modal');
            modal.find('.modal-body')[0].innerHTML = content;
            modal.modal('show');
        }
    });

    cache.$btnOpenCollapse.on('click', function () {
        heightPositionYPDP = window.scrollY;
        let btnCollapseMobile = $(this);
        if(window.isMobile()) {
            if(statusOpenCollapseMobile) {
                closeCollapseVariantMobile(btnCollapseMobile);
            } else {
                cache.$panelVariantMobile.removeClass('hide');
                cache.$body.addClass('modal-open');
                statusOpenCollapseMobile = true;
            }
        }
    });

    $('.js-collapse-variants').off('show.bs.collapse').on('show.bs.collapse', function () {
        var collapse = $(this);
        var btnCollapse = collapse.prev('.js-btn-open-collapse').find('.icon-btn');
        if (btnCollapse.length > 0) {
            btnCollapse.addClass('rotated');
        }
    });

    $('.js-collapse-variants').off('hide.bs.collapse').on('hide.bs.collapse', function () {
        var collapse = $(this);
        var btnCollapse = collapse.prev('.js-btn-open-collapse').find('.icon-btn');
        if (btnCollapse.length > 0) {
            btnCollapse.removeClass('rotated');
        }
    });

    $('.js-form-notifyme').off('submit').on('submit', function (e) {
        e.preventDefault();
        var $form = $(this);
        var url = $form.attr('action');
        $.ajax({
            url: url,
            type: 'post',
            dataType: 'json',
            data: $form.serialize(),
            success: function (data) {
                if (!data.success) {
                    formValidation($form, data);

                    cache.$modalNotifyMe.modal('hide');
                    $(document).trigger('setToastMessage', ['', data.message, false]);
                } else {
                    changeContentModal(data, data.status);
                    var trackingInfo = cache.$productDetail.find('.js-info-tracking');
                    if(trackingInfo.length > 0) {
                        eventHandlers.sendEvent(trackingInfo, eventsType.notifyMe);
                    }
                }
            },
            error: function (err) {
                $(document).trigger('setToastMessage', ['', err.responseJSON.message, false]);
            }
        });
        return false;
    });

    $(document).on('click', '.js-btn-notifyme-retry', function () {
        changeContentModal();
    });

    cache.$btnShowModalNotifyMe.on('click', function () {
        changeContentModal();
    });

    $('.js-modal-zoom-pdp').on('hidden.bs.modal', function () {
        if(!isBodyScrollBlocked) {
            $('body').css('overflow', 'auto');
        }
    });

    cache.$btnFilterColor.on('click', function () {
        var btnColor = $(this);
        cache.$btnFilterColor.removeClass('selected');
        btnColor.addClass('selected');
        var keyColor = btnColor.data('key-color');
        var containerFilter = $(this).parent('.js-container-filter-pdp');
        var configFilter = containerFilter.data('json-filter');

        var elementToLoop = configFilter[keyColor];

        if (keyColor === 'all') {
            cache.$btnAttributeColor.removeClass('d-none');
        } else {
            cache.$btnAttributeColor.each(function(index, elem) {
                var dataAttrValue = $(elem).data('attr-value').toString();
                if (elementToLoop.includes(dataAttrValue)) {
                    $(elem).removeClass('d-none');
                } else {
                    $(elem).addClass('d-none');
                }
            });
        }
    });

};
