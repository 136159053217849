'use strict';

const cache = {
    $selectorSwiper: '.js-pd-swiper',
    $selectorSwiperData: $('.js-pd-swiper')
};

$(window).on('load', function () {
    cache.$selectorSwiperData.each(function (index, swiperElement) {
        const $currentSwiper = $(swiperElement);
        const configSwiper = $currentSwiper.data('swiper-config');

        if (configSwiper) {
            initSwiper($currentSwiper, configSwiper);
        }
    });
});

function initSwiper($currentSwiper, config) {
    const slides = $currentSwiper.find('.swiper-slide');
    const slidesContainVideo = slides.toArray().some(slide =>
        $(slide).find('.video-pd').length > 0
    );

    const swiper = new Swiper($currentSwiper[0], {
        modules: [Autoplay, Navigation, Pagination, Scrollbar],
        slidesPerView: 1,
        spaceBetween: 0,
        loop: !slidesContainVideo,
        autoplay: config.autoplay && (config.slides > 1) ? {
            delay: config.delay
        } : false,
        pagination: {
            el: '.swiper-pagination',
            type: 'bullets',
            clickable: !config.autoplay
        },
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        }
    });

    if (config.delay) {
        $(swiper.el).find('.swiper-pagination-bullet').attr('style', '--speed: ' + config.delay / 1000 + ';');
    }
}
