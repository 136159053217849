import intlTelInput from "intl-tel-input";

function initialisePhoneInput(phoneFieldID) {
    const utils = require('intl-tel-input/build/js/utils');
    const internationalCountry = ['de', 'at', 'pt', 'es'];

    const countryMap = {
        'it': ['it'],
        'cz': ['cz'],
        'fr': ['fr'],
        'hu': ['hu'],
        'ro': ['ro'],
        'ru': ['ru'],
        'sk': ['sk'],
        'de': internationalCountry,
        'at': internationalCountry,
        'pt': internationalCountry,
        'es': internationalCountry
    }

    const phoneInput = document.getElementById(phoneFieldID);

    let initialCountry = phoneInput.dataset.country && phoneInput.dataset.country !== '/'
        ? phoneInput.dataset.country
        : 'de'; // fallback for international website

    let onlyCountries = countryMap[initialCountry];

    const initializationParams = {
        initialCountry: initialCountry,
        onlyCountries: onlyCountries,
        separateDialCode: true,
        utilsScript: utils,
        formatOnDisplay: false
    };

    intlTelInput(phoneInput, initializationParams);
}

function updatePhoneInput(initialCountry) {
    let phoneInput = $('form input[type="tel"]');

    if (phoneInput && phoneInput.length > 0) {
        phoneInput.each(function () {
            const iti = window.intlTelInputGlobals.getInstance(this);

            if (initialCountry) {
                iti.setCountry(initialCountry);
            } else {
                if (iti) {
                    // already initialized
                    const phoneInput = document.getElementById($(this).attr('id'));

                    let country = phoneInput.dataset.country && phoneInput.dataset.country !== '/'
                        ? phoneInput.dataset.country
                        : 'de'; // fallback for international website

                    iti.setCountry(country);
                } else {
                    initialisePhoneInput($(this).attr('id'));
                }
            }
        });
    }
}

$(document).ready(function () {
    updatePhoneInput();

    // change select value
    $('[id*= "_country"]').on('change', function() {
        updatePhoneInput(this.value.toLowerCase());
    });

    $('input[type="tel"]').on('input', function() {
        let value = $(this).val();
        // check if the value contains the "+" character
        if (value.includes("+")) {
            // remove the "+" character from the value
            $(this).val(value.replace(/\+/g, ""));
        }
    });
});
