'use strict';

const eventHandlers = require('../tracking/eventHandlers');
const eventsType = eventHandlers.eventsType;

const cache = {
    $selectorSwiperProduct : '.js-swiper-product',
};

$(window).on('load', function () {
    var carouselContainer = $(cache.$selectorSwiperProduct);
    var infoItems = carouselContainer.find('.js-info-tracking');
    if(infoItems.length > 0) {
        eventHandlers.sendEvent(infoItems, eventsType.viewItemList);
    }
    initSwiperProduct();
});

function initSwiperProduct() {
    const swiperProduct = new Swiper(cache.$selectorSwiperProduct, {
        modules: [Autoplay, Navigation, Pagination, Scrollbar],
        slidesPerView: 4,
        spaceBetween: 28,
        autoHeight: true,
        pagination: {
            el: '.swiper-pagination',
            type: 'fraction'
        },
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
        breakpoints: {
            0: {
                slidesPerView: 1,
            },
            576: {
                slidesPerView: 2,
            },
            992: {
                slidesPerView: 3,
            },
            1200: {
                slidesPerView: 4,
            },
        },
    });
}
