'use strict';

const cache = {
    $corporateLink: $('.corporate-link'),
    $corporateCard: $('.corporate-card'),
    $corporateBackdrop: $('.corporate-backdrop'),
    $closeBtn: $('.corporate-card .border-btn')
};

$(document).ready(function () {
    if (cache.$corporateLink) {
        cache.$corporateLink.on('click', function () {
            if (cache.$corporateCard.hasClass('d-none')) {
                cache.$corporateCard.removeClass('d-none');
            } else {
                cache.$corporateCard.addClass('d-none');
            }
        });

        cache.$corporateLink.on('mouseout', function () {
            if (!window.isMobile()) {
                cache.$corporateCard.addClass('d-none');
            }
        });

        cache.$corporateLink.on('mouseover', function () {
            if (!window.isMobile()) {
                cache.$corporateCard.removeClass('d-none');
            }
        });

        cache.$corporateBackdrop.on('click', function () {
            cache.$corporateCard.addClass('d-none');
        });

        cache.$closeBtn.on('click', function () {
            cache.$corporateCard.addClass('d-none');
        });
    }
});
