'use strict';

const cache = {
    $logoMobile: $('.js-logo-mobile'),
    $header: $('.js-header'),
    $body: $('body')
};

$(document).ready(function() {
    function updateText(label) {
        $('.js-order-select').empty().text(": " + label.trim());
    }

    let selectedRule = $('.js-sort-order .custom-control.custom-radio.sort-radio.checked');
    if (selectedRule.length > 0) {
        updateText(selectedRule.first().text());
    }

    $('input[name="sortOrder"]').change(function() {
        updateText($(this).siblings('.custom-control-label').text());
    });

    $(window).on('resize', function() {
        if (window.isMobile()) {
            $('.dropdown-menu').addClass('d-none');
        } else {
            $('#orderModal').modal('hide');
        }
    });
});
