'use strict'

class EventHandlers {
    constructor() {
        this.eventsInfoImpression = {
            'Tracking:viewitem': (trackingInfo) => this.parseInfoEvent(trackingInfo),
            'Tracking:viewitemlist': (trackingInfo) => this.parseInfoEvent(trackingInfo),
            'Tracking:addtocart': (trackingInfo) => this.parseInfoEventAjax(trackingInfo),
            'Tracking:removefromcart': (trackingInfo) => this.parseInfoEventAjax(trackingInfo),
            'Tracking:viewcart': (trackingInfo) => this.parseInfoEvent(trackingInfo),
            'Tracking:purchase': (trackingInfo) => this.parseInfoPurchaseEvent(trackingInfo),
            'Tracking:selectitem': (trackingProducts) => this.parseInfoEvent(trackingProducts),
            'Tracking:addtowishlist': (trackingProducts) => this.parseInfoEventAjax(trackingProducts),
            'Tracking:begincheckout': (trackingProducts) => this.parseInfoEvent(trackingProducts),
            'Tracking:addshippinginfo': (trackingProducts) => this.parseInfoEventAjax(trackingProducts),
            'Tracking:addpaymentinfo': (trackingProducts) => this.parseInfoEventAjax(trackingProducts)
        }

        this.eventsType = {
            viewItem: 'Tracking:viewitem',
            viewItemList: 'Tracking:viewitemlist',
            addToCart: 'Tracking:addtocart',
            removeFromCart: 'Tracking:removefromcart',
            viewCart: 'Tracking:viewcart',
            beginCheckout: 'Tracking:begincheckout',
            purchase: 'Tracking:purchase',
            selectItem: 'Tracking:selectitem',
            addToWishlist: 'Tracking:addtowishlist',
            addShippingInfo: 'Tracking:addshippinginfo',
            addPaymentInfo: 'Tracking:addpaymentinfo',
        }

        this.pageEvents = {
            'Order-Confirm': ['purchase'],
            'Home-Show': ['select_item', 'view_item_list', 'add_to_cart', 'remove_from_cart', 'add_to_wishlist'],
            'Default-Start': ['select_item', 'view_item_list', 'add_to_cart', 'remove_from_cart', 'add_to_wishlist'],
            'Product-Show': ['select_item', 'view_item', 'view_item_list', 'add_to_cart', 'remove_from_cart', 'add_to_wishlist'],
            'Search-Show': ['select_item', 'view_item_list', 'add_to_cart', 'remove_from_cart', 'add_to_wishlist'],
            'Cart-Show': ['remove_from_cart', 'view_cart', 'select_item', 'add_to_wishlist'],
            'Checkout-Begin': ['begin_checkout', 'add_shipping_info', 'add_payment_info']
        }
    }

    getSupportedEvents(action) {
        var supportedEvents = [];
        var allPageEvents = this.pageEvents;
        Object.keys(allPageEvents).forEach(function (key) {
            if (action && action.includes(key)) {
                supportedEvents = allPageEvents[key];
            }
        });
        return supportedEvents;
    }

    sendEvent(data, event){
        if(data.length && data.length > 0) {
            var trackingInfo = this.eventsInfoImpression[event](data);
            $('body').trigger(event, trackingInfo);
        }
    }


    parseInfoEvent(trackingItems) {
        var items = {};
        items = trackingItems.map((idx, element) => {
            return element.dataset;
        });
        return this.retrieveInfoEvent(items);
    }

    parseInfoEventAjax(trackingItems) {
        return this.retrieveInfoEvent(trackingItems);
    }

    retrieveInfoEvent(trackingItems) {
        var priceTotal = 0;

        trackingItems.items.forEach(function(elem) {
            priceTotal += elem.price;
        });

        var data = {
            items: trackingItems.items,
            currency: trackingItems.currency,
            priceTotal: priceTotal
        }

        return data;
    }

    parseInfoPurchaseEvent(trackingInfo) {
        var data = {};

        data.items = this.parseInfoEvent(trackingInfo.items);
        data.trackingPurchase = trackingInfo.trackingPurchase;

        return data
    }
}

module.exports = EventHandlers;
