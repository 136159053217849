'use strict';

const ACCORDION_OPEN_CLASS = 'accordion--open';

const cache = {
    $body: $('body')
};

function toggleAccordion(event) {
    const btn = $(event.currentTarget);
    const accordionItem = btn.closest('.card');

    if (accordionItem.hasClass(ACCORDION_OPEN_CLASS)) {
        accordionItem.removeClass(ACCORDION_OPEN_CLASS);
        let icon = btn.find('.icon.icon-minus');
        icon.removeClass('icon-minus');
        icon.addClass('icon-plus');
    } else {
        closeOtherAccordions(btn);
        accordionItem.addClass(ACCORDION_OPEN_CLASS);
        let icon = btn.find('.icon.icon-plus');
        icon.removeClass('icon-plus');
        icon.addClass('icon-minus');
    }
}

function closeOtherAccordions(el) {
    let accordion = el.closest('.accordion');

    accordion.find('.card').each((index, card) => {
        const currentCard = $(card);
        if (currentCard.hasClass(ACCORDION_OPEN_CLASS)) {
            const currentIcon = currentCard.find('.icon.icon-minus');
            currentCard.removeClass(ACCORDION_OPEN_CLASS);
            currentIcon.removeClass('icon-minus');
            currentIcon.addClass('icon-plus');
        }
    });
}

cache.$body.on('click', '.accordion .card-header .btn', function (e) {
    toggleAccordion(e);
});
