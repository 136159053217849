'use strict';

const cache = {
    $selectorSwiperMenu : '.js-carousel-menu',
};

$(window).on('load', function () {
    initSwiperMenu();
});

function initSwiperMenu() {
    const swiperMenuBar = new Swiper(cache.$selectorSwiperMenu, {
        modules: [Navigation, Pagination],
        slidesPerView: 1,
        autoHeight: true,
        pagination: {
            el: '.swiper-pagination',
            type: 'fraction'
        },
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        }
    });

}
