'use strict'
const EventHandlers = require('sky_datalayer/tracking/eventHandlers')

class EventHandlersPupa extends EventHandlers {

    constructor() {
        super();
        this.eventsInfoImpression = {
            ...this.eventsInfoImpression,
            'Tracking:removefromcart': (trackingInfo) => this.parseInfoEvent(trackingInfo),
            'Tracking:notify': (trackingNotifyInfo) => this.parseNotifyMe(trackingNotifyInfo)
        }
        this.pageEvents = {
            ...this.pageEvents,
            'Page-Show': ['select_item', 'view_item_list', 'add_to_cart', 'remove_from_cart', 'add_to_wishlist'],
            'Site': ['select_item', 'view_item_list', 'add_to_cart', 'remove_from_cart', 'add_to_wishlist'],
            'Product-Show': ['select_item', 'view_item', 'view_item_list', 'add_to_cart', 'remove_from_cart', 'add_to_wishlist', 'notify'],

        }
        this.eventsType = {
            ...this.eventsType,
            notifyMe: 'Tracking:notify'
        }
    }

    /**
     * Override of retrieveInfoEvent in eventHandlers class
     * @param trackingProducts
     * @returns {{priceTotal: number, currency, items: (*|{})}}
     */
    retrieveInfoEvent(trackingProducts) {
        var currency, data, priceTotal = 0, items = {}, option;
        var newPriceTotal = null;
        if (trackingProducts) {
            currency = trackingProducts[0].currency;
            if (trackingProducts.length > 1) {
                // there are multiple items and the total price is calculated as the sum of all prices
                priceTotal = trackingProducts[0].total_amount ? trackingProducts[0].total_amount : null;
            } else {
                // there is only one item
                priceTotal = trackingProducts[0].price;
            }
            
            option = trackingProducts[0].option ? trackingProducts[0].option : null;

            items = trackingProducts.map((idx, element) => {
                if (!priceTotal) {
                    var partialPrice = element.price ? parseFloat(element.price) : 0;
                    newPriceTotal += partialPrice ? partialPrice : 0
                }

                return element;
            });

            data = {
                items,
                priceTotal: priceTotal || newPriceTotal,
                currency
            }

            if (option) {
                data.option = option;
            }
        }
        return data;
    }

    parseInfoEvent(trackingItems, coupon) {
        var items = {};
        items = trackingItems.map((idx, element) => {
            var dataset = {...element.dataset};
            var couponCode = coupon || dataset.coupon;
            dataset.coupon = this.parseCoupon(couponCode);
            return dataset;
        });
        return this.retrieveInfoEvent(items);
    }

    parseInfoPurchaseEvent(trackingInfo) {
        var data = {};
        var trackingPurchase;
        trackingPurchase = trackingInfo[0].trackingPurchase;
        data.trackingPurchase = {...trackingPurchase[0].dataset};
        data.trackingItems = this.parseInfoEvent(trackingInfo[0].productTiles, data.trackingPurchase.promo_code);
        data.trackingPurchase.promo_code = this.parseCoupon(data.trackingPurchase.promo_code);
        return data
    }

    parseCoupon(coupon) {
        coupon = coupon.replace(/^\[|\]$/g, '');

        var resultArray = coupon.split(',').map(function (item) {
            return item.trim();
        }).filter(Boolean);

        return resultArray;
    }

    parseNotifyMe(trackingNotifyInfo) {
        var data = {};
        data["back_in_stock"] = trackingNotifyInfo.data('id');
        return data;
    }
}

module.exports = new EventHandlersPupa();
