'use strict';

/**
 * Cache DOM elements related to social share functionality
 */
const cache = {
    $body: $('body'),
    $shareDropdownButton: $('.js-shareDropdown'),
    $shareDropdownShare: $('.js-dropdown-share'),
    $shareDropdownMenu: $('.js-dropdown-share-menu'),
    $shareModal: $('#shareModal'),
};

/**
 * Initialize UI behavior based on window size
 */
$(document).ready(function() {
    $(window).on('resize', function() {
        if (window.isMobile()) {
            cache.$shareDropdownMenu.addClass('d-none');
        } else {
            cache.$shareModal.modal('hide');
            cache.$shareDropdownMenu.removeClass('d-none');
        }
    });

    cache.$shareDropdownShare.on('show.bs.dropdown', function () {
        $(this).find(cache.$shareDropdownButton).removeClass('icon-share');
        $(this).find(cache.$shareDropdownButton).addClass('icon-close');
        $(this).find(cache.$shareDropdownButton).addClass('active');
    });

    cache.$shareDropdownShare.on('hide.bs.dropdown', function () {
        $(this).find(cache.$shareDropdownButton).removeClass('active');
        $(this).find(cache.$shareDropdownButton).removeClass('icon-close');
        $(this).find(cache.$shareDropdownButton).addClass('icon-share');
    });
});

/**
 * Set up metadata and call the function to set social share links when the share button is clicked
 */
$(document).ready(function () {
    var currentUrl = window.location.href;
    var encodedUrl = encodeURIComponent(currentUrl);
    var data = cache.$shareDropdownButton.data();

    if (data) {
        var emailUrl = data.mail + data.name + encodedUrl;

        $("meta[property='og\\:image']").attr("content", data.image);
        $("meta[property='og\\:title']").attr("content", data.name);
        $("meta[property='og\\:description']").attr("content", data.description);
        $("meta[name='twitter\\:title']").attr("content", data.name);
        $("meta[name='twitter\\:image']").attr("content", data.image);

        setSocialShareLinks ( encodedUrl, data.name, data.image, emailUrl, true);
    }
});

/**
 * Set URLs for social sharing buttons
 * @param {string} url - URL to share
 * @param {string} title - Title of the content to share
 * @param {string} image - Image URL of the content to share
 * @param {string} mailUrl - Email URL for sharing
 * @param {boolean} alreadyHasParams - Whether the URL already has URL parameters
 */
function setSocialShareLinks(url, title, image, mailUrl, alreadyHasParams) {
    const prefix = alreadyHasParams ? '%26utm_source=' : '%3Futm_source=';
    const suffix = '%26utm_medium=post-organico%26utm_campaign=shareWebsite';

    const shareData = [
        { platform: 'copyLink', url: decodeURIComponent(url), addParams: false },
        { platform: 'facebook', url: `https://www.facebook.com/sharer/sharer.php?u=${url}`, addParams: true },
        { platform: 'twitter', url: `http://twitter.com/share?text=${title}&url=https://${url}&image-src=${image}`, addParams: true },
        { platform: 'pinterest', url: `http://pinterest.com/pin/create/button/?url=${url}&media=${image}&description=${title}`, addParams: true },
        { platform: 'mail', url: mailUrl, addParams: true }
    ];

    shareData.forEach(({ platform, url, addParams }) => {
        const target = `.js-${platform}`;
        let href = url;

        if (addParams) {
            const params = `${prefix}${platform}${suffix}`;
            href += params;
        }

        $(target).attr('href', href);
    });
}

/**
 * Copy the URL to clipboard when the 'Copy Link' button is clicked
 * @param {Event} event - JavaScript event object
 */
$(document).on('click', '.js-copyLink', function(event) {
    event.preventDefault();
    var urlToCopy = $(this).attr('href');
    var successMessage = $(this).attr('data-success');
    var errorMessage = $(this).attr('data-error');
    var title = $(this).parent().attr('data-title');
    if (urlToCopy) {
        navigator.clipboard.writeText(decodeURIComponent(urlToCopy)).then(() => {
            $(document).trigger('setToastMessage', [title, successMessage, true]);
        }).catch(() => {
            $(document).trigger('setToastMessage', [title, errorMessage, false]);
        });
    }
});
