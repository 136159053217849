'use strict';

const eventHandlers = require('../tracking/eventHandlers');
const eventsType = eventHandlers.eventsType;

function changeIcon (heart) {
    if (heart.hasClass('js-add-to-wishlist')) {
        heart.removeClass('icon-heart');
        heart.removeClass('js-add-to-wishlist');

        heart.addClass('icon-heart-full');
        heart.addClass('js-remove-from-wishlist');
    } else {
        heart.removeClass('icon-heart-full');
        heart.removeClass('js-remove-from-wishlist');

        heart.addClass('icon-heart');
        heart.addClass('js-add-to-wishlist');
    }
}

module.exports = {
    addToWishlist: function () {
        $('body').on('click', '.js-add-to-wishlist', function (e) {
            e.preventDefault();
            const heart = $(this);
            const url = heart.data('add-to-wishlist');
            const pid = heart.data('pid');
            const addError = heart.data('add-to-wishlist-error');
            const title = heart.data('title');

            $.ajax({
                url,
                type: 'post',
                dataType: 'json',
                data: {
                    pid,
                },
                success: function (data) {
                    changeIcon(heart);

                    if(Object.keys(data.trackingProduct).length !== 0) {
                        eventHandlers.sendEvent($(data.trackingProduct), eventsType.addToWishlist);
                    }

                    if ($('.js-product-tile-wishlist').length === 0 && $('.page').data('action') === 'Wishlist-Show') {
                        location.reload();
                    }
                },
                error: function () {
                    $(document).trigger('setToastMessage', [title, addError, false]);
                }
            });
        });
    },

    removeFromWishlist: function () {
        $('body').on('click', '.js-remove-from-wishlist', function (e) {
            e.preventDefault();
            const heart = $(this);
            const url = heart.data('remove-from-wishlist');

            if ($('.js-product-tile-wishlist').length === 0) {
                $.ajax({
                    url: url,
                    type: 'get',
                    dataType: 'json',
                    data: {},
                    success: function () {
                        changeIcon(heart);
                    }
                });
            }
        });
    },

    removeFromWishlistDashboard: function () {
        $('.container-wishlist .js-remove-from-wishlist').on('click', function (e) {
            e.preventDefault();
            let btnRemoveWishlist = $(this);
            let url = $(this).data('url');
            const removeError = $(this).data('remove-from-wishlist-error');
            const title = $(this).data('title');

            $.ajax({
                url: url,
                type: 'get',
                dataType: 'json',
                data: {},
                success: function () {
                    if ($('.js-product-tile-wishlist').length === 1) {
                        location.reload();
                    } else {
                        btnRemoveWishlist.closest('.js-col-card-wishlist').remove();
                    }
                },
                error: function () {
                    $(document).trigger('setToastMessage', [title, removeError, false]);
                }
            });
        });
    },
};
