'use strict';

// Configuration Constants for Toast Messages and Icons
const AUTO_CLOSE_INTERVAL = 4000;
const BASE_TOAST_CLASS = 'show alertBottom ';
const SUCCESS_TOAST_CLASS = BASE_TOAST_CLASS + 'alert-success';
const FAILURE_TOAST_CLASS = BASE_TOAST_CLASS + 'alert-danger';
const SUCCESS_ICON_CLASS = 'icon-check-message';
const FAILURE_ICON_CLASS = 'icon-check-error';

const TOAST_CLASSES_TO_REMOVE = 'alert-success alert-danger';
const ICON_CLASSES_TO_REMOVE = 'icon-check-message icon-check-error';

var cache = {
    $btnCloseMessage: $('.js-btn-close-message'),
    $alert: $('.js-alert-status'),
    $alertIcon: $('.js-icon-alert'),
    $alertSection: $('.js-alert-section'),
    $alertMessage: $('.js-alert-message')
};

/**
 * Hide the toast message on close button click.
 * @listens click
 */
cache.$btnCloseMessage.on('click', function () {
    $(this).closest('.js-alert-status').removeClass('show');
});

/**
 * Automatically dismiss alert elements after a 2-second interval.
 * This is particularly useful for alert elements that are not generated by this JavaScript file, such as those in a dashboard.
 * @listens ready
 */
$(document).ready(function() {
    if ($('.js-alert-status').length > 0) {
        setTimeout(function() {
            $('.js-alert-status').removeClass('show');
        }, 5000);
    }
});

/**
 * Automatically close the toast after a given interval
 *
 * @param {number} interval - The time interval in milliseconds after which the toast should be closed
 */
function autoCloseToast(interval) {
    setTimeout(function() {
        cache.$alert.removeClass('show');
    }, interval);
}

/**
 * Display a toast message based on the given parameters
 *
 * @param {string} section - The section where the alert should be displayed
 * @param {string} message - The message to be displayed in the alert
 * @param {boolean} isSuccess - Determines whether the alert should be styled as 'success' or 'danger'
 */
function setToastMessage(section, message, isSuccess) {
    const toastClass = isSuccess ? SUCCESS_TOAST_CLASS : FAILURE_TOAST_CLASS;
    const iconClass = isSuccess ? SUCCESS_ICON_CLASS : FAILURE_ICON_CLASS;

    cache.$alert.removeClass(TOAST_CLASSES_TO_REMOVE);
    cache.$alertIcon.removeClass(ICON_CLASSES_TO_REMOVE);

    cache.$alert.addClass(toastClass);
    cache.$alertIcon.addClass(iconClass);

    cache.$alertSection.text(section);
    cache.$alertMessage.text(message);

    autoCloseToast(AUTO_CLOSE_INTERVAL);
}

/**
 * Event listener for a custom 'setToastMessage' event to trigger toast notifications
 *
 * @listens setToastMessage
 * @param {Event} event - The jQuery Event object
 * @param {string} section - The section where the alert should be displayed
 * @param {string} message - The message to be displayed in the alert
 * @param {boolean} isSuccess - Determines whether the alert should be styled as 'success' or 'danger'
 */
$(document).on('setToastMessage', function(event, section, message, isSuccess) {
    setToastMessage(section, message, isSuccess);
});
