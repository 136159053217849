'use strict';

class Tracking {
    constructor() {
        this.eventsParser = {
            'add_to_cart': (data) => this.parseItems(data),
            'view_item': (data) => this.parseItems(data),
            'remove_from_cart': (data) => this.parseItems(data),
            'view_item_list': (data) => this.parseItems(data),
            'view_cart': (data) => this.parseItems(data),
            'begin_checkout': (data) => this.parseItems(data),
            'purchase': (data) => this.parsePurchase(data),
            'select_item': (data) => this.parseItems(data),
            'add_to_wishlist': (data) => this.parseItems(data),
            'add_shipping_info': (data) => this.parseShippingItems(data),
            'add_payment_info': (data) => this.parsePaymentItems(data),
        }

        this.itemModel = {
            "item_id": "id",
            "item_name": "name",
            "discount": "discount",
            "item_brand": "brand",
            "price": "price",
            "quantity": "quantity"
        }

        this.purchaseModel = {
            "transaction_id": "transaction_id",
            "value": "tot_revenue",
            "coupon": "promo_code",
            "shipping": "shipping_cost",
            "tax": "tax_fee",
        }
    }

    parseAttributes(keys, data, model) {
        var newKey, itemGA4 = {};
        var item = data[0] ? data[0] : data;

        if(item) {
            keys.forEach(function(key) {
                newKey = model[key];
                itemGA4[key] = item[newKey] ? item[newKey] : '';
            });
        }

        return itemGA4;
    }

    parsePurchase(data) {
        var self = this, dataGA4 = {};
        var itemKeys = Object.keys(self.itemModel);
        var purchaseKeys = Object.keys(self.purchaseModel);

        var items = data.trackingItems.items.map(function() {
            var obj = self.parseAttributes(itemKeys, $(this), self.itemModel);
            return obj;
        });

        dataGA4 = self.parseAttributes(purchaseKeys, data.trackingPurchase, self.purchaseModel);

        dataGA4.items = items.toArray();
        dataGA4.value = data.trackingItems.priceTotal;
        dataGA4.currency = data.trackingItems.currency;

        return dataGA4;
    }

    parseShippingItems(data) {
        var dataGA4 = this.parseItems(data);
        dataGA4.shipping_tier = data.option;
        return dataGA4;
    }

    parsePaymentItems(data) {
        var dataGA4 = this.parseItems(data);
        dataGA4.payment_type = data.option;
        return dataGA4;
    }

    parseItems(data) {
        var self = this, dataGA4 = {};
        const keys = Object.keys(self.itemModel);

        var items = data.items.map(function() {
            var obj = self.parseAttributes(keys, $(this), self.itemModel);
            return obj;
        });

        dataGA4.items = items.toArray();
        return dataGA4;
    }

    pushGA4Data (data, eventName) {
        gtag("event", eventName, data);
    }

    trackingImpression(data) {
        var dataGA4 = this.eventsParser[data.event](data);
        this.pushGA4Data(dataGA4, data.event);
    }
}


module.exports = Tracking;
