'use strict';

const cache = {
    $selectorSwiperCardGallery: '.js-swiper-card-gallery',
};

$(window).on('load', function () {
    initSwiperCardGallery();
});

function initSwiperCardGallery() {
    const swiperCardGallery = new Swiper(cache.$selectorSwiperCardGallery, {
        modules: [Navigation, Pagination, Grid],
        slidesPerView: 4,
        spaceBetween: 0,
        autoHeight: true,
        pagination: {
            el: '.swiper-pagination',
            type: 'fraction'
        },
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
        breakpoints: {
            0: {
                slidesPerView: 1,
                grid: {
                    rows: 1
                },
            },
            768: {
                slidesPerView: 2,
                grid: {
                    rows: 2
                },
            },
            1024: {
                slidesPerView: 4,
                slidesPerRow: 1,
            }
        },
    });
}
