'use strict';

var base = require('base/login/login');

const formValidation = require('../components/formValidation');

base.register = function () {
    $('form.registration').submit(function (e) {
        var form = $(this);
        e.preventDefault();
        var url = form.attr('action');
        form.spinner().start();
        $('form.registration').trigger('login:register', e);
        $.ajax({
            url: url,
            type: 'post',
            dataType: 'json',
            data: form.serialize(),
            success: function (data) {
                form.spinner().stop();
                if (!data.success) {
                    var valuesFields = Object.keys(data.fields);
                    $('form.registration').trigger('login:register:error', data);
                    formValidation(form, data);
                    if (valuesFields.length > 0) {
                        var firstField = valuesFields[0];
                        var selector = $("#" + firstField);
                        selector[0].scrollIntoView({ behavior: "smooth", block: "center", inline: "center" });
                    }
                } else {
                    $('form.registration').trigger('login:register:success', data);
                    location.href = data.redirectUrl;
                }
            },
            error: function (err) {
                if (err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                } else {
                    $(document).trigger('setToastMessage', ['', err.responseJSON.message, false]);
                }

                form.spinner().stop();
            }
        });
        return false;
    });
}

base.resetPassword = function () {
    $('.reset-password-form').submit(function (e) {
        var form = $(this);
        e.preventDefault();
        var url = form.attr('action');
        form.spinner().start();
        $('.reset-password-form').trigger('login:register', e);

        $('.alert', form).remove();

        $.ajax({
            url: url,
            type: 'post',
            dataType: 'json',
            data: form.serialize(),
            success: function (data) {
                $.spinner().stop();
                if (!data.success) {
                    formValidation(form, data);
                    if (data.errorMsg) {
                        form.prepend('<div class="alert alert-danger d-block" role="alert">'
                            + data.errorMsg + '</div>');
                    }
                } else {
                    $('.request-password-title').text(data.receivedMsgHeading);
                    $('.request-password-body').empty()
                        .append('<p>' + data.receivedMsgBody + '</p>');
                    if (!data.mobile) {
                        $('#submitEmailButton').text(data.buttonText)
                            .attr('data-dismiss', 'modal');
                    } else {
                        $('.send-email-btn').empty()
                            .html('<a href="'
                                + data.returnUrl
                                + '"><button type="button" class="btn btn-primary-black">'
                                + data.buttonText + '</button></a>'
                            );
                    }
                }
            },
            error: function () {
                $.spinner().stop();
            }
        });
        return false;
    });

    $('#requestPasswordResetModal').on('hidden.bs.modal', function () {
        if ($('#submitEmailButton').attr('data-dismiss')) {
            $('#loginSidebar').modal('show');
        }
    });

    $('#loginSidebar').on('hidden.bs.modal', function () {
        if (($("#requestPasswordResetModal").data('bs.modal') || {})._isShown) {
            $("#requestPasswordResetModal").modal('hide');
            $("#requestPasswordResetModal").modal('show');
            $('body').addClass('modal-open');
        }
    });
}

base.submitSetNewPassword = function () {
    $('form.set-new-password-form').submit(function (e) {
        $('.invalid-feedback').text('');
        $('.form-control').removeClass('is-invalid');

        var $form = $(this);
        e.preventDefault();
        var url = $form.attr('action');
        $.spinner().start();

        $.ajax({
            url: url,
            type: 'post',
            dataType: 'json',
            data: $form.serialize(),
            success: function (data) {
                $.spinner().stop();
                if (!data.success) {
                    $('.invalid-feedback').text(data.fields['dwfrm_newPasswords_newpasswordconfirm']);
                    $('.form-control').addClass('is-invalid');
                } else {
                    location.href = data.redirectUrl;
                }
            },
            error: function (err) {
                if (err.responseJSON && err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                }
                $.spinner().stop();
            }
        });
        return false;
    });
}

module.exports = base;
