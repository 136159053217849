'use strict';

$('.controls-container .icon-volume-off, .controls-container .icon-volume-on').on('click', function () {
    const $this = $(this);
    const controlsContainer = $this.closest('.controls-container');
    const isMobile = controlsContainer.hasClass('mobile-controls');
    const video = $this.closest('.experience-component').find((isMobile ? '.mobile-video' : '.desktop-video') + ' video')[0];
    const icon = controlsContainer.find('.icon-volume-on, .icon-volume-off');

    if (icon.hasClass('icon-volume-on')) {
        video.muted = true;
        icon.removeClass('icon-volume-on').addClass('icon-volume-off');
    } else {
        video.muted = false;
        icon.removeClass('icon-volume-off').addClass('icon-volume-on');
    }
});

$('.controls-container .icon-play, .controls-container .icon-pause').on('click', function () {
    const $this = $(this);
    const controlsContainer = $this.closest('.controls-container');
    const isMobile = controlsContainer.hasClass('mobile-controls');
    const video = $this.closest('.experience-component').find((isMobile ? '.mobile-video' : '.desktop-video') + ' video')[0];
    const icon = controlsContainer.find('.icon-play, .icon-pause');

    if (icon.hasClass('icon-play')) {
        video.play();
        icon.removeClass('icon-play').addClass('icon-pause');
    } else {
        video.pause();
        icon.removeClass('icon-pause').addClass('icon-play');
    }
});

$(window).on('resize', function () {
    const isMobile = window.isMobile();
    const jsClass = isMobile ? '.desktop' : '.mobile';

    $(`.experience-component ${jsClass}-video video`).each(function () {
        if (!this.muted) {
            this.muted = true;

            $(this).closest('.experience-component').find(`.controls-container${jsClass}-controls .icon-volume-on`)
                .removeClass('icon-volume-on')
                .addClass('icon-volume-off');
        }
    });
});
