import 'bootstrap';

window.jQuery = window.$ = require('jquery');
import Swiper, {Autoplay, Navigation, Pagination, Scrollbar, Thumbs, Grid, FreeMode, Mousewheel, Parallax} from 'swiper';
window.Swiper = Swiper;
window.Thumbs = Thumbs;
window.Grid = Grid;
window.Pagination = Pagination;
window.Navigation = Navigation;
window.Autoplay = Autoplay;
window.Scrollbar = Scrollbar;
window.FreeMode = FreeMode;
window.Mousewheel = Mousewheel;
window.Parallax = Parallax;
var processInclude = require('base/util');

$(document).ready(function () {
    processInclude(require('./components/menu'));
    processInclude(require('./components/footer'));
    processInclude(require('./components/miniCart'));
    processInclude(require('base/components/collapsibleItem'));
    processInclude(require('base/components/countrySelector'));
    processInclude(require('base/components/toolTip'));
    processInclude(require('./components/search'));
    processInclude(require('./components/customFormValidation'));
    processInclude(require('./components/01-components/accordion'));
    processInclude(require('./login'));
    processInclude(require('./utils/mobileCheck'));
    processInclude(require('./utils/drag'));
    processInclude(require('./utils/phoneInput'));
    processInclude(require('./productTile'));
    processInclude(require('./wishlist'));
    processInclude(require('./components/carouselMenu'));
    processInclude(require('./product/components/zoomImage'));
    processInclude(require('./productDetail'));
    processInclude(require('./components/01-components/toastMessage'));
    processInclude(require('./carousels'));
    processInclude(require('./slot/slot'));
    processInclude(require('./search'));
    processInclude(require('./newsletter/newsletter'));

    // page designer
    // they don't work if they're in remote includes, so they need to be included here
    processInclude(require('./experience/productCarouselDescription'));
    processInclude(require('./experience/tutorialCarouselDescription'));
    processInclude(require('./experience/card'));
    processInclude(require('./experience/cardGallery'));
    processInclude(require('./experience/carouselProduct'));
    processInclude(require('./experience/slider'));
    processInclude(require('./experience/bannerFullWidthDoubleObscure'));
    processInclude(require('./experience/video'));
});

const TrackingEvents = require('./tracking/trackingEvents');
TrackingEvents.trackingHandler();

require('lazysizes');
require('lazysizes/plugins/bgset/ls.bgset');
require('slick-carousel/slick/slick');
require('./components/spinner');
