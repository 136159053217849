'use strict';

const Tracking = require('sky_datalayer/tracking/tracking');

class TrackingPupa extends Tracking {
    constructor() {
        super();

        this.itemModel = {
            ...this.itemModel,
            "affiliation": '',
            "coupon": 'coupon',
            "item_name": "productName",
            "item_category": "item_category",
            "item_category2": "item_category2",
            "item_category3": "item_category3",
            "item_list_id": '',
            "item_list_name": '',
            "item_variant": "item_variant",
            "location_id": '',
        }

        this.notifyModel = {
            "back_in_stock": "back_in_stock"
        }

        this.eventsParser = {
            ...this.eventsParser,
            'notify': (data) => this.parseNotify(data)
        }
    }

    parseItems(data) {
        var dataGA4 = {}, idx = 0, self = this;
        var model = self.itemModel;
        const keys = Object.keys(model);

        var items = data.items.map(function() {
            var obj = self.parseAttributes(keys, this, model);
            obj.index = idx;
            idx++;
            return obj;
        });

        dataGA4.items = items.toArray();
        dataGA4.value = data.priceTotal;
        dataGA4.currency = data.currency;

        return dataGA4;
    }

    parseNotify(data) {
        var model = this.notifyModel;
        const keys = Object.keys(model);
        return this.parseAttributes(keys, data, model);
    }

}

module.exports = TrackingPupa;
