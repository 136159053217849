'use strict';

var cart = require('../cart/cart');

var updateMiniCart = true;

const cache = {
    $body: $('body'),
    $minicart: $('.minicart')
}

module.exports = function () {
    cart();

    cache.$minicart.on('count:update', function (event, count) {
        if (count && $.isNumeric(count.quantityTotal)) {
            $('.minicart-quantity').text(count.quantityTotal);
        }
    });

    cache.$body.on('change', '.minicart .quantity', function () {
        if ($(this).parents('.bonus-product-line-item').length && $('.cart-page').length) {
            location.reload();
        }
    });

    cache.$body.on('product:afterAddToCart', function () {
        updateMiniCart = true;
    });

    cache.$body.on('cart:update', function () {
        updateMiniCart = true;
    });
};
