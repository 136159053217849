'use strict';

var base = require('../product/base');
const eventHandlers = require('../tracking/eventHandlers');
const eventsType = eventHandlers.eventsType;

const cache = {
    $body: $('body'),
    $summary: $('.js-summary'),
    $couponCodeField: $('.coupon-code-field'),
    $productInfoContainer: $('.product-info-container'),
    $cartErrorMessaging: $('.cart-error-messaging')
};

/**
 * appends params to a url
 * @param {string} url - Original url
 * @param {Object} params - Parameters to append
 * @returns {string} result url with appended parameters
 */
function appendToUrl(url, params) {
    var newUrl = url;
    newUrl += (newUrl.indexOf('?') !== -1 ? '&' : '?') + Object.keys(params).map(function (key) {
        return key + '=' + encodeURIComponent(params[key]);
    }).join('&');

    return newUrl;
}

/**
 * Checks whether the basket is valid. if invalid displays error message and disables
 * checkout button
 * @param {Object} data - AJAX response from the server
 */
function validateBasket(data) {
    if (data.valid.error) {
        if (data.valid.message) {
            var errorHtml = '<div class="heading6 text-red mb-3"> ' + data.valid.message + '</div>';

            $('.cart-error').empty();
            $('.cart-error').append(errorHtml);
        } else {
            $('.cart').empty().append('<div class="card h-100"> ' +
                '<div class="card-body d-flex flex-column justify-content-between"> ' + data.resources.emptyCartMsg +
                '<button type="button" class="btn btn-primary-black js-close-minicart position-relative" data-dismiss="modal">' +
                '<span>' + data.resources.continueShoppingMsg + '</span>' +
                '<span class="icon icon-long-arrow-right position-absolute pp-lh-1 pp-top-0 pp-right-0 mt-1 mr-3"></span>' +
                '</button>' +
                '</div> ' +
                '</div>'
            );
            $('.number-of-items').empty();
            $('.minicart-quantity').empty().append(data.numItems);
            $('.minicart-link').attr({
                'aria-label': data.resources.minicartCountOfItems,
                title: data.resources.minicartCountOfItems
            });
        }

        $('.checkout-btn').addClass('disabled');
    } else {
        $('.cart-error').empty();
        $('.checkout-btn').removeClass('disabled');
    }
}

/**
 * re-renders the order totals and the number of items in the cart
 * @param {Object} data - AJAX response from the server
 */
function updateCartTotals(data) {
    $('.number-of-items').empty().append('(' + data.numItems + ')');
    $('.shipping-cost').empty().append(data.totals.totalShippingCost);
    $('.tax-total').empty().append(data.totals.totalTax);
    $('.grand-total').empty().append(data.totals.grandTotal);
    $('.grand-total-sum').empty().append(data.totals.grandTotal);
    $('.sub-total').empty().append(data.totals.subTotal);
    $('.minicart-quantity').empty().append(data.numItems);
    $('.minicart-link').attr({
        'aria-label': data.resources.minicartCountOfItems,
        title: data.resources.minicartCountOfItems
    });
    if (data.totals.orderLevelDiscountTotal.value > 0) {
        $('.order-discount').removeClass('hide-order-discount');
        $('.order-discount-total').empty()
            .append('- ' + data.totals.orderLevelDiscountTotal.formatted);
    } else {
        $('.order-discount').addClass('hide-order-discount');
    }

    if (data.totals.shippingLevelDiscountTotal.value > 0) {
        $('.shipping-discount').removeClass('hide-shipping-discount');
        $('.shipping-discount-total').empty().append('- ' +
            data.totals.shippingLevelDiscountTotal.formatted);
    } else {
        $('.shipping-discount').addClass('hide-shipping-discount');
    }

    data.items.forEach(function (item) {
        if (data.totals.orderLevelDiscountTotal.value > 0) {
            $('.coupons-and-promos').empty().append(data.totals.discountsHtml);
        }
        if (item.renderedPromotions) {
            $('.item-' + item.UUID).empty().append(item.renderedPromotions);
        } else {
            $('.item-' + item.UUID).empty();
        }

        if (!item.bonusProductLineItemUUID || item.bonusProductLineItemUUID !== 'bonus') {
            $('.uuid-' + item.UUID + ' .unit-price').empty().append(item.renderedPrice);
            $('.line-item-price-' + item.UUID + ' .unit-price').empty().append(item.renderedPrice);
            $('.item-total-' + item.UUID).empty().append(item.priceTotal.renderedPrice);
        } else if (item.bonusProducts && Array.isArray(item.bonusProducts)) {
            item.bonusProducts.forEach(function (bonusProduct) {
                var targetElement = $('.quantity[data-uuid="' + bonusProduct.UUID + '"]');
                if (targetElement.length > 0) {
                    targetElement.text(bonusProduct.quantity);
                    targetElement.data('value', bonusProduct.quantity);

                    var cardProduct = targetElement.closest('.bonus-product-line-item');
                    if (cardProduct.length > 0) {
                        var labelButton = cardProduct.find('.btn-bonus-options');
                        if (labelButton.length > 0) {
                            labelButton.text(bonusProduct.labels)
                        }
                    }
                }
            });
        }
    });

    $('klarna-placement').attr('data-purchase-amount', data.totals.formattedTotalGrossPrice);

    if (window.Klarna) {
        window.Klarna.OnsiteMessaging.refresh();
    }
}

function updateCartProductList(data) {
    let productInfoContainer = $('.product-info-container .main-container-product-list');
    let productSummaryBlock = $('.product-summary-block');
    let miniCartBody = $('.minicart-body');

    if (productInfoContainer && productInfoContainer.length > 0 && data.cartProductInfoContainer) {
        productInfoContainer.empty().html(data.cartProductInfoContainer);
    }

    if (productSummaryBlock && productSummaryBlock.length > 0 && data.orderProductListSummary) {
        productSummaryBlock.each(function () {
            $(this).empty().html(data.orderProductListSummary);
        });
    }

    if (miniCartBody && miniCartBody.length > 0 && data.miniCartBody) {
        miniCartBody.empty().html(data.miniCartBody);
    }

    if(data.csrf) {
        $('.promo-form-mobile .promo-code-form input[type="hidden"]').attr('name', data.csrf.tokenName);
        $('.promo-form-mobile .promo-code-form input[type="hidden"]').attr('value', data.csrf.token);
    }

    $('.promo-form-mobile .promo-code-form').submit(addPromoCode);
}

/**
 * re-renders the order totals and the number of items in the cart
 * @param {Object} message - Error message to display
 */
function createErrorNotification(message) {
    var errorHtml = '<div class="heading6 text-red mb-3"> ' + message + '</div>';

    cache.$cartErrorMessaging.append(errorHtml);
}

/**
 * re-renders the approaching discount messages
 * @param {Object} approachingDiscounts - updated approaching discounts for the cart
 */
function updateApproachingDiscounts(approachingDiscounts) {
    var html = '';
    $('.approaching-discounts').empty();
    if (approachingDiscounts.length > 0) {
        approachingDiscounts.forEach(function (item) {
            html += '<div class="single-approaching-discount">' + item.discountMsg + '</div>';
        });
    }
    $('.approaching-discounts').append(html);
}

/**
 * Updates the availability of a product line item
 * @param {Object} data - AJAX response from the server
 * @param {string} uuid - The uuid of the product line item to update
 */
function updateAvailability(data, uuid) {
    var lineItem;
    var messages = '';

    for (var i = 0; i < data.items.length; i++) {
        if (data.items[i].UUID === uuid) {
            lineItem = data.items[i];
            break;
        }
    }

    if (lineItem != null) {
        $('.availability-' + lineItem.UUID).empty();

        if (lineItem.availability) {
            if (lineItem.availability.messages) {
                lineItem.availability.messages.forEach(function (message) {
                    messages += '<p class="line-item-attributes">' + message + '</p>';
                });
            }

            if (lineItem.availability.inStockDate) {
                messages += '<p class="line-item-attributes line-item-instock-date">'
                    + lineItem.availability.inStockDate
                    + '</p>';
            }
        }

        $('.availability-' + lineItem.UUID).html(messages);
    }
}

/**
 * Finds an element in the array that matches search parameter
 * @param {array} array - array of items to search
 * @param {function} match - function that takes an element and returns a boolean indicating if the match is made
 * @returns {Object|null} - returns an element of the array that matched the query.
 */
function findItem(array, match) { // eslint-disable-line no-unused-vars
    for (var i = 0, l = array.length; i < l; i++) {
        if (match.call(this, array[i])) {
            return array[i];
        }
    }
    return null;
}

/**
 * Updates details of a product line item
 * @param {Object} data - AJAX response from the server
 * @param {string} uuid - The uuid of the product line item to update
 */
function updateProductDetails(data, uuid) {
    $('.card.product-info.uuid-' + uuid).replaceWith(data.renderedTemplate);
}

/**
 * Parses the html for a modal window
 * @param {string} html - representing the body and footer of the modal window
 *
 * @return {Object} - Object with properties body and footer.
 */
function parseHtml(html) {
    var $html = $('<div>').append($.parseHTML(html));

    var body = $html.find('.product-quickview');
    var footer = $html.find('.modal-footer').children();

    return {body: body, footer: footer};
}

function updateQuantity(quantityObj) {
    var preSelectQty = quantityObj.quantityInput.data('pre-select-qty');
    var quantity = quantityObj.newQuantity;
    var productID = quantityObj.quantityInput.data('pid');
    var url = quantityObj.quantityInput.data('action');
    var uuid = quantityObj.quantityInput.data('uuid');

    var urlParams = {
        pid: productID,
        quantity: quantity,
        uuid: uuid
    };
    url = appendToUrl(url, urlParams);

    cache.$body.trigger('cart:beforeUpdate');

    $.ajax({
        url: url,
        type: 'get',
        context: this,
        dataType: 'json',
        success: function (data) {
            if (data.valid.error && data.items.length !== 0) {
                cache.$cartErrorMessaging.find('heading6').text(data.valid.message);
                $('.checkout-btn').find('button').attr('disabled', true);
            } else {
                $('.checkout-btn').find('button').attr('disabled', false);
            }

            if (quantityObj.typeProduct === 'bonus') {
                $('.quantity[data-uuid="' + uuid + '"]').data('value', quantity);
            } else {
                $('.quantity[data-uuid="' + uuid + '"]').val(quantity);
            }

            $('.coupons-and-promos').empty().append(data.totals.discountsHtml);

            updateCartTotals(data);
            updateCartProductList(data);
            updateApproachingDiscounts(data.approachingDiscounts);
            updateAvailability(data, uuid);
            validateBasket(data);
            quantityObj.quantityInput.data('pre-select-qty', quantity);

            cache.$body.trigger('cart:update', data);

            if (quantityObj.typeProduct === 'bonus') {
                if ((quantityObj.type === 'minus' && quantityObj.newQuantity == 1)
                    || (quantityObj.type === 'plus' && quantityObj.newQuantity == quantityObj.maxPidsBonus)) {
                    quantityObj.this.prop('disabled', true).addClass('disabled');
                } else {
                    quantityObj.this.prop('disabled', false).removeClass('disabled');
                }
            } else {
                if ((quantityObj.type === 'minus' && quantityObj.newQuantity == parseInt(quantityObj.quantityInput[0].min, 10))
                    || (quantityObj.type === 'plus' && quantityObj.newQuantity == parseInt(quantityObj.quantityInput[0].max, 10))) {
                    quantityObj.this.prop('disabled', true).addClass('disabled');
                } else {
                    quantityObj.this.prop('disabled', false).removeClass('disabled');
                }
            }

            var infoTracking = $('.js-info-tracking[data-id=' + productID + ']');
            if (infoTracking.length > 0) {
                var trackingQuantity = infoTracking.data('quantity');
                var priceTotal = infoTracking.data('price');
                var singlePrice = priceTotal / trackingQuantity;
                infoTracking.attr('data-quantity', quantityObj.newQuantity);
                infoTracking.attr('data-price', singlePrice * trackingQuantity);
            }

            let oppositeInput = quantityObj.type === 'minus' ? 'plus' : 'minus';
            quantityObj.inputGroup.find('.btn-' + oppositeInput).prop('disabled', false).removeClass('disabled');
        },
        error: function (err) {
            if (err.responseJSON.redirectUrl) {
                window.location.href = err.responseJSON.redirectUrl;
            } else {
                createErrorNotification(err.responseJSON.errorMessage);
                quantityObj.quantityInput.val(parseInt(preSelectQty, 10));
            }
        }
    });
}

/**
 * Function to push view_cart event for dataLayer
 * @param lineItemName
 */
function pushViewCartEvent(infoItems) {
    if(infoItems.length > 0) {
        eventHandlers.sendEvent(infoItems, eventsType.viewCart);
    }
}

function addPromoCode(e) {
    e.preventDefault();

    $('.coupon-missing-error').hide();
    $('.coupon-error-message').empty();

    let couponCodeField = $(this).find('.coupon-code-field');

    if (!couponCodeField.val()) {
        $('.promo-code-form .form-control').addClass('is-invalid');
        $('.promo-code-form .form-control').attr('aria-describedby', 'missingCouponCode');
        $('.coupon-missing-error').show();
        return false;
    }

    var $form = $(this);
    $('.promo-code-form .form-control').removeClass('is-invalid');
    $('.coupon-error-message').empty();
    cache.$body.trigger('promotion:beforeUpdate');

    document.activeElement.blur();

    $.ajax({
        url: $form.data('action'),
        type: 'GET',
        dataType: 'json',
        data: $form.serialize(),
        success: function (data) {
            cache.$couponCodeField.next('.focus-placeholder').removeClass('d-block');
            if (data.error) {
                $('.promo-code-form .form-control').addClass('is-invalid');
                $('.promo-code-form .form-control').attr('aria-describedby', 'invalidCouponCode');
                $('.coupon-error-message').empty().append(data.errorMessage);
                cache.$body.trigger('promotion:error', data);
            } else {
                $('.coupons-and-promos').empty().append(data.totals.discountsHtml);
                updateCartTotals(data);
                updateCartProductList(data);
                updateApproachingDiscounts(data.approachingDiscounts);
                validateBasket(data);
                cache.$body.trigger('promotion:success', data);
            }

            couponCodeField[0].value = '';
        },
        error: function (err) {
            cache.$body.trigger('promotion:error', err);
            if (err.responseJSON.redirectUrl) {
                window.location.href = err.responseJSON.redirectUrl;
            } else {
                createErrorNotification(err.errorMessage);
            }
        }
    });
    return false;
}

$(document).ready(function () {
    var infoItems = cache.$productInfoContainer.find('.line-item-header');
    if (infoItems.length > 0) {
        infoItems.on('click', function () {
            var infoTracking = $(this).find('.js-info-tracking');
            if(infoTracking) {
                eventHandlers.sendEvent(infoTracking, eventsType.selectItem);
            }
        });

        var infoItems = cache.$productInfoContainer.find('.product-info .js-info-tracking');
        pushViewCartEvent(infoItems);
    }
});

module.exports = function () {
    $('.optional-promo').click(function (e) {
        e.preventDefault();
        $('.promo-code-form').toggle();
    });

    cache.$body.on('click', '.js-remove-product', function (e) {
        e.preventDefault();
        var self = $(this);
        var productID = self.data('pid');
        var url = self.data('action');
        var uuid = self.data('uuid');
        var urlParams = {
            pid: productID,
            uuid: uuid
        };

        var bonusCard;
        var bonusProduct = self.data('bonus-product');

        var isMinicart = self.closest('.js-minicart').length > 0;

        url = appendToUrl(url, urlParams);

        cache.$body.trigger('cart:beforeUpdate');

        $.ajax({
            url: url,
            type: 'get',
            dataType: 'json',
            success: function (data) {
                var miniCartContainer = self.closest('.product-line-item');
                var cartContainer = self.closest('.product-info');
                var infoTracking = cartContainer.length > 0 ? cartContainer.find('.js-info-tracking') : miniCartContainer.find('.js-info-tracking');
                if (infoTracking.length > 0) {
                    eventHandlers.sendEvent(infoTracking, eventsType.removeFromCart);
                }

                if (data.basket.items.length === 0 && !isMinicart) {
                    location.reload();
                } else {
                    if (data.toBeDeletedUUIDs && data.toBeDeletedUUIDs.length > 0) {
                        for (var i = 0; i < data.toBeDeletedUUIDs.length; i++) {
                            $('.uuid-' + uuid).find('.card-body').addClass('product-card-fade');

                            setTimeout(function () {
                                $('.uuid-' + data.toBeDeletedUUIDs[i]).remove();
                            }, 1000);
                        }
                    }

                    if (bonusProduct) {
                        var bonusCardMiniCart = $('.js-line-item-bonus[data-pid-bonus="' + productID + '"]');
                        if (bonusCardMiniCart.length > 0) {
                            bonusCard = bonusCardMiniCart;
                            bonusCard.addClass('product-card-fade');
                        } else {
                            bonusCard = $('.js-bundle-line-item-bonus[data-pid-bonus="' + productID + '"]');
                        }
                    }

                    $('.uuid-' + uuid).find('.card-body').addClass('product-card-fade');

                    setTimeout(function () {
                        $('.uuid-' + uuid).remove();
                        if (bonusProduct && bonusCard.length > 0) {
                            bonusCard.remove();
                        }
                        if (!data.basket.hasBonusProduct) {
                            $('.bonus-product').remove();
                        }
                        $('.coupons-and-promos').empty().append(data.basket.totals.discountsHtml);
                        updateCartTotals(data.basket);
                        updateCartProductList(data.basket);
                        updateApproachingDiscounts(data.basket.approachingDiscounts);
                        cache.$body.trigger('setShippingMethodSelection', data.basket);
                        validateBasket(data.basket);
                    }, 1000);
                }

                cache.$body.trigger('cart:update', data);
            },
            error: function (err) {
                if (err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                } else {
                    createErrorNotification(err.responseJSON.errorMessage);
                }
            }
        });
    });

    $('.shippingMethods').change(function () {
        var url = $(this).attr('data-actionUrl');
        var urlParams = {
            methodID: $(this).find(':selected').attr('data-shipping-id')
        };
        // url = appendToUrl(url, urlParams);

        $('.totals').spinner().start();
        cache.$body.trigger('cart:beforeShippingMethodSelected');
        $.ajax({
            url: url,
            type: 'post',
            dataType: 'json',
            data: urlParams,
            success: function (data) {
                if (data.error) {
                    window.location.href = data.redirectUrl;
                } else {
                    $('.coupons-and-promos').empty().append(data.totals.discountsHtml);
                    updateCartTotals(data);
                    updateApproachingDiscounts(data.approachingDiscounts);
                    validateBasket(data);
                }

                cache.$body.trigger('cart:shippingMethodSelected', data);
                $.spinner().stop();
            },
            error: function (err) {
                if (err.redirectUrl) {
                    window.location.href = err.redirectUrl;
                } else {
                    createErrorNotification(err.responseJSON.errorMessage);
                    $.spinner().stop();
                }
            }
        });
    });

    $('.promo-code-form').submit(addPromoCode);

    cache.$couponCodeField.on('focusout', function () {
        if ($(this).val() !== '') {
            $(this).next('.focus-placeholder').addClass('d-block');
        } else {
            $(this).next('.focus-placeholder').removeClass('d-block');
        }

        if (cache.$shippingForm) {
            cache.$shippingForm.trigger('input');
        }
    });

    cache.$body.on('click', '.remove-coupon', function (e) {
        e.preventDefault();

        var url = $(this).data('action');
        var uuid = $(this).data('uuid');
        var couponCode = $(this).data('code');
        var urlParams = {
            code: couponCode,
            uuid: uuid
        };

        url = appendToUrl(url, urlParams);

        cache.$body.trigger('promotion:beforeUpdate');
        $.ajax({
            url: url,
            type: 'get',
            dataType: 'json',
            success: function (data) {
                $('.coupon-uuid-' + uuid).remove();
                updateCartTotals(data);
                updateCartProductList(data);
                updateApproachingDiscounts(data.approachingDiscounts);
                validateBasket(data);

                cache.$body.trigger('promotion:success', data);

                $('.promo-code-form .form-control').removeClass('is-invalid');
                $('.coupon-error-message').empty();
            },
            error: function (err) {
                cache.$body.trigger('promotion:error', err);
                if (err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                } else {
                    createErrorNotification(err.responseJSON.errorMessage);
                }
            }
        });
    });

    cache.$body.on('click', '.js-bonus-product-button', function () {
        $.spinner().start();
        $(this).addClass('launched-modal');
        $.ajax({
            url: $(this).data('url'),
            method: 'GET',
            dataType: 'json',
            success: function (data) {
                base.methods(data);
                $.spinner().stop();
            },
            error: function () {
                $.spinner().stop();
            }
        });
    });

    cache.$body.on('product:updateAddToCart', function (e, response) {
        // update global add to cart (single products, bundles)
        var dialog = $(response.$productContainer)
            .closest('.quick-view-dialog');

        $('.update-cart-product-global', dialog).attr('disabled',
            !$('.global-availability', dialog).data('ready-to-order')
            || !$('.global-availability', dialog).data('available')
        );
    });

    cache.$body.on('product:updateAvailability', function (e, response) {
        // bundle individual products
        $('.product-availability', response.$productContainer)
            .data('ready-to-order', response.product.readyToOrder)
            .data('available', response.product.available)
            .find('.availability-msg')
            .empty()
            .html(response.message);


        var dialog = $(response.$productContainer)
            .closest('.quick-view-dialog');

        if ($('.product-availability', dialog).length) {
            // bundle all products
            var allAvailable = $('.product-availability', dialog).toArray()
                .every(function (item) {
                    return $(item).data('available');
                });

            var allReady = $('.product-availability', dialog).toArray()
                .every(function (item) {
                    return $(item).data('ready-to-order');
                });

            $('.global-availability', dialog)
                .data('ready-to-order', allReady)
                .data('available', allAvailable);

            $('.global-availability .availability-msg', dialog).empty()
                .html(allReady ? response.message : response.resources.info_selectforstock);
        } else {
            // single product
            $('.global-availability', dialog)
                .data('ready-to-order', response.product.readyToOrder)
                .data('available', response.product.available)
                .find('.availability-msg')
                .empty()
                .html(response.message);
        }
    });

    cache.$body.on('product:afterAttributeSelect', function (e, response) {
        if ($('.modal.show .product-quickview .bundle-items').length) {
            $('.modal.show').find(response.container).data('pid', response.data.product.id);
            $('.modal.show').find(response.container).find('.product-id').text(response.data.product.id);
        } else {
            $('.modal.show .product-quickview').data('pid', response.data.product.id);
        }
    });

    cache.$body.on('change', '.quantity-select', function () {
        var selectedQuantity = $(this).val();
        $('.modal.show .update-cart-url').data('selected-quantity', selectedQuantity);
    });

    cache.$body.on('change', '.options-select', function () {
        var selectedOptionValueId = $(this).children('option:selected').data('value-id');
        $('.modal.show .update-cart-url').data('selected-option', selectedOptionValueId);
    });

    cache.$body.on('click', '.btn-minus', function (e) {
        e.preventDefault();
        var btnMinus = $(this);
        let inputGroup = btnMinus.parent('.input-group');
        let quantityInput = inputGroup.find('.quantity');

        if (quantityInput) {

            if (quantityInput.val()) {
                var currentQuantity = parseInt(quantityInput.val());

                if (currentQuantity > quantityInput[0].min) {
                    let newQuantity = currentQuantity - 1;
                    // quantityInput.val(parseInt(newQuantity, 10).toString());
                    // quantityInput.attr('value', newQuantity);

                    var quantityObj = {
                        type: 'minus',
                        this: $(this),
                        quantityInput: quantityInput,
                        newQuantity: newQuantity,
                        inputGroup: inputGroup
                    };

                    updateQuantity(quantityObj);
                }
            } else {

                var currentQuantityBonus = parseInt(quantityInput.data('value'));
                var min = quantityInput.data('min');
                if (currentQuantityBonus > min) {
                    var newQuantity = currentQuantityBonus - 1;

                    var quantityObjBonus = {
                        type: 'minus',
                        typeProduct: 'bonus',
                        this: $(this),
                        quantityInput: quantityInput,
                        newQuantity: newQuantity,
                        inputGroup: inputGroup
                    };

                    updateQuantity(quantityObjBonus);
                }
            }
        }
    });

    cache.$body.on('click', '.btn-plus', function (e) {
        e.preventDefault();
        var btnPlus = $(this);
        let inputGroup = btnPlus.parent('.input-group');
        let quantityInput = inputGroup.find('.quantity');


        if (quantityInput) {
            if (quantityInput.val()) {
                var currentQuantity = parseInt(quantityInput.val());
                if (currentQuantity < quantityInput[0].max) {
                    let newQuantity = currentQuantity + 1;
                    // quantityInput.val(parseInt(newQuantity, 10).toString());
                    // quantityInput.attr('value', newQuantity);

                    var quantityObj = {
                        type: 'plus',
                        typeProduct: 'product',
                        this: $(this),
                        quantityInput: quantityInput,
                        newQuantity: newQuantity,
                        inputGroup: inputGroup
                    };
                    updateQuantity(quantityObj);
                }
            } else {
                var currentQuantityBonus = parseInt(quantityInput.data('value'));
                var maxPidsBonus = quantityInput.data('max');

                if (currentQuantityBonus < maxPidsBonus) {
                    var newQuantity = currentQuantityBonus + 1;

                    var quantityObjBonus = {
                        type: 'plus',
                        typeProduct: 'bonus',
                        this: $(this),
                        quantityInput: quantityInput,
                        newQuantity: newQuantity,
                        inputGroup: inputGroup,
                        maxPidsBonus: maxPidsBonus
                    };
                    updateQuantity(quantityObjBonus);
                }
            }
        }
    });

    cache.$body.on('click', '.update-cart-product-global', function (e) {
        e.preventDefault();

        var updateProductUrl = $(this).closest('.cart-and-ipay').find('.update-cart-url').val();
        var selectedQuantity = $(this).closest('.cart-and-ipay').find('.update-cart-url').data('selected-quantity');
        var selectedOptionValueId = $(this).closest('.cart-and-ipay').find('.update-cart-url').data('selected-option');
        var uuid = $(this).closest('.cart-and-ipay').find('.update-cart-url').data('uuid');

        var form = {
            uuid: uuid,
            pid: base.getPidValue($(this)),
            quantity: selectedQuantity,
            selectedOptionValueId: selectedOptionValueId
        };

        $(this).parents('.card').spinner().start();

        cache.$body.trigger('cart:beforeUpdate');

        if (updateProductUrl) {
            $.ajax({
                url: updateProductUrl,
                type: 'post',
                context: this,
                data: form,
                dataType: 'json',
                success: function (data) {
                    $('.coupons-and-promos').empty().append(data.cartModel.totals.discountsHtml);
                    updateCartTotals(data.cartModel);
                    updateApproachingDiscounts(data.cartModel.approachingDiscounts);
                    updateAvailability(data.cartModel, uuid);
                    updateProductDetails(data, uuid);

                    if (data.uuidToBeDeleted) {
                        $('.uuid-' + data.uuidToBeDeleted).remove();
                    }

                    validateBasket(data.cartModel);

                    cache.$body.trigger('cart:update', data);

                    $.spinner().stop();
                },
                error: function (err) {
                    if (err.responseJSON.redirectUrl) {
                        window.location.href = err.responseJSON.redirectUrl;
                    } else {
                        createErrorNotification(err.responseJSON.errorMessage);
                        $.spinner().stop();
                    }
                }
            });
        }
    });

    cache.$body.on('click', '.mobile-summary', function (e) {
        e.preventDefault();

        cache.$summary.removeClass('d-none');
        $('.product-info-container').addClass('d-none');
        cache.$body.addClass('menu-open');
    });

    function closeMobileSummary() {
        cache.$summary.addClass('d-none');
        cache.$body.removeClass('menu-open');
        $('.product-info-container').removeClass('d-none');
    }

    cache.$body.on('click', '.js-btn-close-summary', function () {
        closeMobileSummary();
    });

    $(window).on('resize', function () {
        if (!window.isMobile() && cache.$summary.length > 0) {
            closeMobileSummary();
        }
    });

    $('#couponCodeMobile').on('focusout', function () {
        window.scrollTo(0, 0);
    });

    base.selectAttribute();
    base.colorAttribute();
    base.removeBonusProduct();
    base.selectBonusProduct();
    base.enableBonusProductSelection();
    base.showMoreBonusProducts();
    base.addBonusProductsToCart();
};
